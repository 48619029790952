var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fieldset',{staticClass:"new-shipment-address-meeting"},[_c('legend',{staticClass:"tw-text-base",attrs:{"id":"address-meeting"},domProps:{"textContent":_vm._s(_vm.$t('new-shipment.titles.meeting'))}}),_c('ValidationProvider',{attrs:{"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
return [_c('ui-fat-radio-group',{staticClass:"tw-flex tw-flex-col md:tw-flex-row tw-mb-5",attrs:{"items":_vm.items,"aria-labelledby":"address-meeting"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
var active = ref.active;
var keydown = ref.keydown;
return [_c('ui-fat-radio-item',{staticClass:"tw-w-full md:tw-w-1/2 not-last:tw-mb-4 not-last:md:tw-mr-4 not-last:md:tw-mb-0",attrs:{"title":item.title,"active":active,"error":invalid && errors.length > 0},nativeOn:{"keydown":function($event){return keydown.apply(null, arguments)},"click":function($event){_vm.rdvValue = item.value}}},[_c('div',{staticClass:"new-shipment-address-meeting__radio-title tw-text-sm tw-mb-1 tw-font-medium",domProps:{"textContent":_vm._s(item.title)}}),_c('div',{staticClass:"new-shipment-address-meeting__radio-content tw-text-xs",domProps:{"textContent":_vm._s(item.content)}})])]}}],null,true),model:{value:(_vm.rdvValue),callback:function ($$v) {_vm.rdvValue=$$v},expression:"rdvValue"}})]}}])}),(_vm.rdvValue)?[_c('new-shipment-alert',[_c('p',{staticClass:"tw-mb-0",domProps:{"textContent":_vm._s(_vm.$t('new-shipment.paragraphs.meeting.explanation'))}})]),_c('ValidationProvider',{attrs:{"name":_vm.$t('new-shipment.fields.meeting'),"rules":"required|max:4000","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var errors = ref.errors;
return [_c('ctk-input-textarea',{staticClass:"tw-mb-12",attrs:{"id":"meeting","label":_vm.$t('new-shipment.labels.meeting'),"error":invalid && errors.length > 0,"hint":errors[0],"name":"meeting","required":""},model:{value:(_vm.commentValue),callback:function ($$v) {_vm.commentValue=$$v},expression:"commentValue"}})]}}],null,false,288950591)})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }