var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new-shipment-template tw-px-4"},[_c('div',{staticClass:"ctk-container tw-pb-8"},[_c('h1',{staticClass:"new-shipment-template__title tw-relative tw-font-medium tw-text-2xl tw-mt-8 tw-mb-5",domProps:{"textContent":_vm._s(_vm.$t('new-shipment.titles.model_type'))}}),_c('div',{staticClass:"new-shipment-template__layout tw-relative tw-flex tw-flex-col md:tw-flex-row tw-py-5 tw-px-4 tw-justify-center tw-border tw-border-solid tw-border-gray-300 tw-bg-white md:tw-py-12"},[_c('new-shipment-template-link',{staticClass:"tw-w-full tw-mb-5 md:tw-w-4/12 md:tw-mr-5 md:tw-mb-0",attrs:{"to":{
          name: 'NewShipmentTemplateList'
        },"title":_vm.$t('new-shipment.labels.type.template.title'),"subtitle":_vm.$t('new-shipment.labels.type.template.subtitle')},nativeOn:{"click":function($event){return _vm.selectTemplate.apply(null, arguments)}}}),_c('new-shipment-template-link',{staticClass:"tw-w-full md:tw-w-4/12",attrs:{"to":{
          name: 'NewShipmentAddress',
          params: {
            direction: 'pickup'
          }
        },"title":_vm.$t('new-shipment.labels.type.new.title'),"subtitle":_vm.$t('new-shipment.labels.type.new.subtitle')},nativeOn:{"click":function($event){return _vm.selectNew.apply(null, arguments)}}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }