var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new-shipment-handling"},[_c('h2',{staticClass:"new-shipment-handling__title tw-font-normal",domProps:{"textContent":_vm._s(_vm.$t('new-shipment.titles.handling'))}}),_c('ValidationObserver',{ref:"observer",attrs:{"slim":""}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitted.apply(null, arguments)}}},[_c('div',{staticClass:"tw-flex new-shipment-handling__layout"},_vm._l((['pickup', 'delivery']),function(direction){return _c('new-shipment-handling-direction',{key:direction,staticClass:"tw-flex-1",attrs:{"direction":direction}},[_c('ValidationProvider',{ref:"driver",refInFor:true,attrs:{"name":(direction + "-driver"),"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
return [_c('new-shipment-handling-driver',{staticClass:"mb-4",attrs:{"direction":direction,"name":(direction + "-driver"),"error":invalid ? errors[0] : null},on:{"input":function($event){return _vm.updateGuard(direction)}},model:{value:(_vm.formData.driver[direction]),callback:function ($$v) {_vm.$set(_vm.formData.driver, direction, $$v)},expression:"formData.driver[direction]"}})]}}],null,true)}),_c('ValidationProvider',{ref:"tail-lift",refInFor:true,attrs:{"name":(direction + "-tail-lift"),"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
return [_c('new-shipment-handling-tail-lift',{attrs:{"direction":direction,"name":(direction + "-tail-lift"),"error":invalid ? errors[0] : null},on:{"input":function($event){return _vm.updateGuard(direction)}},model:{value:(_vm.formData.tail_lift[direction]),callback:function ($$v) {_vm.$set(_vm.formData.tail_lift, direction, $$v)},expression:"formData.tail_lift[direction]"}})]}}],null,true)})],1)}),1),_c('new-shipment-alert',{staticClass:"new-shipment-handling__explanation tw-mt-5"},[_c('p',[_vm._v(" * "+_vm._s(_vm.$t('new-shipment.paragraphs.handling.explanation.handling_price'))+" ")]),_c('ul',[_c('li',[_vm._v(" "+_vm._s(_vm.$t('new-shipment.paragraphs.handling.explanation.handling_price.standard'))+" ")]),_c('li',[_vm._v(" "+_vm._s(_vm.$t('new-shipment.paragraphs.handling.explanation.handling_price.custom'))+" ")])]),_c('p',{staticClass:"tw-mb-0"},[_vm._v(" ** "+_vm._s(_vm.$t('new-shipment.paragraphs.handling.explanation.tail_lift'))+" ")])]),(_vm.filteredErrors.length > 0)?_c('div',{staticClass:"mt-3"},_vm._l((_vm.filteredErrors),function(error){return _c('div',{key:error.id,staticClass:"error-banner px-3 py-2 mb-2"},[_vm._v(" "+_vm._s(error.msg)+" ")])}),0):_vm._e(),_c('div',{staticClass:"new-shipment-handling__buttons tw-flex tw-flex-col-reverse 2sm:tw-flex-row 2sm:tw-justify-between tw-mt-6"},[_c('div',{staticClass:"tw-mt-4 2sm:tw-mt-0"},[_c('ui-button',{staticClass:"tw-w-full 2sm:tw-w-auto",attrs:{"to":{
              name: 'NewShipmentGoods'
            },"variant":"link"},nativeOn:{"click":function($event){return _vm.back.apply(null, arguments)}},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('ui-material-icon',{attrs:{"name":"keyboard_arrow_left"}})]},proxy:true}])},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t('back')))+" ")])],1),_c('ui-button',{attrs:{"loading":_vm.$wait.is('fetching addresses distance'),"disabled":_vm.$wait.is('fetching addresses distance'),"variant":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('new-shipment.buttons.save_handling'))+" ")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }