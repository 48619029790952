<template>
  <ctk-dialog
    transparent-header
    :value="isOpened"
    hide-footer
    bottom-ribbon
    @handle-close="handleClose()"
  >
    <div class="warning-dialog tw-w-full tw-flex tw-flex-col tw-justify-center tw-align-center">
      <picture
        class="tw-object-contain tw-select-none tw-text-center"
      >
        <img
          src="@/assets/img/warning.svg"
          alt=""
        >
      </picture>

      <h5 class="tw-my-5 tw-w-full tw-text-center tw-text-xl">
        {{ $t('new-shipment.custom_price.warning_dialog.title') }}
      </h5>

      <p class="warning-dialog-paragraph">
        {{ $t('new-shipment.custom_price.warning_dialog.description') }}
      </p>
      <p class="warning-dialog-paragraph">
        {{ $t('new-shipment.custom_price.warning_dialog.open_hour') }}
      </p>

      <ui-button
        @click="handleConfirm()"
        class="tw-mt-5"
      >
        {{ $t('new-shipment.custom_price.warning_dialog.confirm_button') }}
      </ui-button>
    </div>
  </ctk-dialog>
</template>
<script>
  import CtkDialog from '@/components/CtkDialog/index.vue'
  import { defineComponent } from '@vue/composition-api'

  export default defineComponent({
    name: 'NewShipmentQuotationCustomPriceWarningDialog',
    components: {
      CtkDialog
    },
    emits: ['on-close', 'on-confirm'],
    props: {
      isOpened: {
        type: Boolean
      }
    },
    setup (_, { emit }) {
      const handleClose = () => {
        emit('on-close')
      }

      const handleConfirm = () => {
        console.log('CONFIRM')
        emit('on-confirm')
      }

      return {
        handleClose,
        handleConfirm
      }
    }
  })
</script>
<style lang="scss" scoped>
.warning-dialog {
  padding-inline: 60px;
}
.warning-dialog-paragraph {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(117, 116, 116, var(--tw-text-opacity));
  font-size: 12px;
}
</style>
