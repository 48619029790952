<template>
  <div
    class="shipment-summary-card-price-type tw-flex tw-items-center tw-bg-blue-500 tw-font-medium tw-text-center tw-text-base tw-text-white tw-absolute tw-px-6"
    data-test="price-type"
    v-text="offers[priceType]"
  />
</template>

<script>
  import { offers } from '@/../config/chronoinfos'

  /**
   * @module component - ShipmentSummaryCardPriceType
   */
  export default {
    name: 'ShipmentSummaryCardPriceType',
    props: {
      priceType: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        offers
      }
    }
  }
</script>

<style lang="scss" scoped>

  .shipment-summary-card-price-type {
    min-height: 30px;
  }

</style>
