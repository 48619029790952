<template>
  <div class="new-shipment-template tw-px-4">
    <div class="ctk-container tw-pb-8">
      <h1
        class="new-shipment-template__title tw-relative tw-font-medium tw-text-2xl tw-mt-8 tw-mb-5"
        v-text="$t('new-shipment.titles.model_type')"
        data-test="title"
      />

      <div class="new-shipment-template__layout tw-relative tw-flex tw-flex-col md:tw-flex-row tw-py-5 tw-px-4 tw-justify-center tw-border tw-border-solid tw-border-gray-300 tw-bg-white md:tw-py-12">
        <new-shipment-template-link
          class="tw-w-full tw-mb-5 md:tw-w-4/12 md:tw-mr-5 md:tw-mb-0"
          :to="{
            name: 'NewShipmentTemplateList'
          }"
          :title="$t('new-shipment.labels.type.template.title')"
          :subtitle="$t('new-shipment.labels.type.template.subtitle')"
          data-test="template"
          @click.native="selectTemplate"
        />
        <new-shipment-template-link
          class="tw-w-full md:tw-w-4/12"
          :to="{
            name: 'NewShipmentAddress',
            params: {
              direction: 'pickup'
            }
          }"
          :title="$t('new-shipment.labels.type.new.title')"
          :subtitle="$t('new-shipment.labels.type.new.subtitle')"
          data-test="new"
          @click.native="selectNew"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import store from '@/store'
  import Hotjar from '@/plugins/VueHotjar'
  import NewShipmentTemplateLink from './components/NewShipmentTemplateLink/index.vue'

  /**
   * @module component - NewShipmentTemplate
   */
  export default defineComponent({
    name: 'NewShipmentTemplate',
    components: {
      NewShipmentTemplateLink
    },
    metaInfo () {
      return {
        title: this.$t('new-shipment.titles.main')
      }
    },
    // @ts-ignore
    async beforeRouteEnter (to, from, next) {
      if (!store.getters.isUserShipper) {
        next({
          name: 'Offers'
        })
        return
      }

      if (store.getters['auth/isAccountLocked']) {
        next({
          name: 'Shipments'
        })
        return
      }

      store.dispatch('setAppReady', true)
      next()
    },
    methods: {
      selectTemplate () {
        Hotjar.tag('Duplicate shipments')

        if (this.$matomo) {
          this.$matomo.trackEvent('Quotations', 'New From Existing')
        }
      },
      selectNew () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Quotations', 'New From Scratch')
        }
      }
    }
  })
</script>

<style lang="scss" scoped>
.new-shipment-template {
  overflow-y: auto !important;
}
.new-shipment-template__layout {
  padding-bottom: 270px;
}
.new-shipment-template__layout::after {
  content: '';
  position: absolute;
  bottom: 50px;
  right: 0;
  background-image: url('~@/assets/img/illustrations/shipments.svg');
  background-size: contain;
  background-position: right center;
  background-repeat: no-repeat;
  opacity: 0.8;
  height: 220px;
  width: 100%;
}
@media (min-width: 770px) {
  .new-shipment-template .new-shipment-template-list__layout::after {
    width: 660px;
  }
}
</style>
