<template>
  <nav
    class="new-shipment-mobile-navigation tw-truncate"
  >
    <ul class="tw-flex tw-truncate tw-flex-1">
      <li
        v-for="(item, k) in items"
        :key="k"
        :class="{
          'active': isActive(k),
          'is-last': isLastActive(k)
        }"
        class="tw-truncate tw-flex-1"
        data-test="item"
      >
        <UiLink
          :to="item.to"
          :event="isActive(k) ? 'click' : ''"
          :aria-disabled="!isActive(k)"
          class="tw-flex new-shipment-mobile-navigation__item tw-items-center tw-justify-center"
          data-test="link"
        >
          <span
            class="tw-truncate"
            v-text="item.title"
          />
        </UiLink>
      </li>
    </ul>
  </nav>
</template>

<script>
  /**
   * @module component - NewShipmentMobileNavigation
   * @param {number} active
   */
  export default {
    name: 'NewShipmentMobileNavigation',
    props: {
      active: {
        type: Number,
        required: true
      }
    },
    computed: {
      items () {
        return [
          {
            to: {
              name: 'NewShipmentAddress',
              params: {
                direction: 'pickup'
              }
            },
            title: this.$t('new-shipment.buttons.mobile-nav.addresses')
          },
          {
            to: {
              name: 'NewShipmentGoods'
            },
            title: this.$t('new-shipment.buttons.mobile-nav.goods')
          },
          {
            to: {
              name: 'NewShipmentHandling'
            },
            title: this.$t('new-shipment.buttons.mobile-nav.handling')
          },
          {
            to: {
              name: 'NewShipmentDates'
            },
            title: this.$t('new-shipment.buttons.mobile-nav.dates')
          }
        ]
      },
      isActive () {
        return k => k <= this.active
      },
      isLastActive () {
        return k => {
          const actives = this.items.filter((v, _k) => {
            return _k <= this.active
          })
          return actives.length === k + 1
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

  .new-shipment-mobile-navigation {
    border: 1px solid $divider;
    height: 45px;
    border-radius: 2px;
    background: white;

    ul,
    li {
      margin: 0;
      padding: 0;
      list-style-type: none;
      height: 100%;
    }

    &__item {
      position: relative;
      height: 100%;
      padding: 0 8px;
      margin-right: 10px;
      color: $secondary-text;
      text-decoration: none;
    }

    li:not(.active):not(:last-child) {
      .new-shipment-mobile-navigation__item {
        &::after,
        &::before {
          content: '';
          position: absolute;
          right: 0;
          width: 0;
          border: 1px solid $divider;
          height: 25.5px;
        }

        &::before {
          top: -2px;
          transform: rotate(-27deg);
        }

        &::after {
          bottom: -2px;
          transform: rotate(27deg);
        }
      }
    }

    li.active {
      &:not(.is-last) {
        background-color: $info;

        .new-shipment-mobile-navigation__item {
          &::after,
          &::before {
            content: '';
            position: absolute;
            right: 0;
            width: 0;
            border: 1px solid white;
            height: 25.5px;
          }

          &::before {
            top: -2px;
            transform: rotate(-27deg);
          }

          &::after {
            bottom: -2px;
            top: auto;
            transform: rotate(27deg);
          }
        }
      }

      &.is-last:last-child {
        background-color: $info;
      }

      /* stylelint-disable */
      .new-shipment-mobile-navigation__item {
        background-color: $info;
        color: white;

        &::after {
          content: '';
          position: absolute;
          right: -10px;
          top: 0;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 22.5px 0 22.5px 10px;
          border-color: transparent transparent transparent $info;
        }
      }
      /* stylelint-enable */
    }
  }

</style>
