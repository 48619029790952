<template>
  <ValidationObserver
    ref="observer"
    slim
  >
    <form
      class="new-shipment-address-add tw-flex tw-flex-1 tw-flex-col"
      :disabled="$wait.is('adding address')"
      @submit.prevent="submitted"
    >
      <h2
        class="new-shipment-address-add__title tw-font-normal tw-mb-4"
        data-test="title"
      >
        {{ $t(direction === 'pickup'
          ? 'new-shipment.titles.add_address.pickup'
          : 'new-shipment.titles.add_address.delivery') }}
      </h2>

      <ValidationProvider
        ref="address-provider"
        :name="$t('new-shipment.fields.address')"
        :rules="{required: true, country: formData.address && formData.address.country}"
        slim
      >
        <template slot-scope="{ invalid, errors }">
          <autocomplete-address-input
            ref="address"
            v-model="formData.addressQuery"
            :direction="direction"
            :disabled="$wait.is('adding address')"
            :error="invalid ? errors[0] : null"
            :disabled-countries="disabledCountries"
            :country.sync="country"
            name="address"
            class="mb-3"
            data-test="address"
            @autocomplete="updateAddress"
            @reset="resetAddress"
            @clear="cancel"
            @toggle-country="toggleCountry"
          />
        </template>
      </ValidationProvider>

      <new-shipment-address-available-countries
        v-if="!(hadAddress || hasPrefilledAddress)"
        :class="{
          faded: isCountrySelectVisible
        }"
        :disabled-countries="disabledCountries"
        @select-country="selectCountry"
      />

      <ValidationProvider
        v-if="hasCompanyField"
        :name="$t('new-shipment.fields.company')"
        rules="required|max:255"
        slim
      >
        <template slot-scope="{ invalid, validated, errors }">
          <ctk-input-text
            id="company"
            v-model="formData.companyName"
            :label="$t('new-shipment.labels.company')"
            :error="invalid && validated"
            :hint="errors[0]"
            :disabled="$wait.is('adding address')"
            type="text"
            name="company"
            class="mb-3"
            required
            data-test="companyName"
          />
        </template>
      </ValidationProvider>

      <template v-if="hadAddress || hasPrefilledAddress">
        <!-- Contact informations -->
        <fieldset
          data-test="contact"
          class="tw-mb-4"
        >
          <i18n
            path="new-shipment.labels.contact_informations"
            tag="legend"
            data-test="title"
            class="tw-text-base"
          >
            <template #optional>
              <span
                class="tw-text-xs tw-text-gray-700 tw-italic"
                v-text="`(${$options.filters.capitalize($t('optional'))})`"
              />
            </template>
          </i18n>

          <div class="tw-flex tw-flex-col lg:tw-flex-row">
            <ValidationProvider
              :name="$t('new-shipment.fields.contact.name')"
              rules="max:128"
              slim
            >
              <template slot-scope="{ invalid, validated, errors }">
                <ctk-input-text
                  id="contact_name"
                  v-model="formData.contact.name"
                  :label="$t('new-shipment.labels.contact.name')"
                  :error="invalid && validated"
                  :hint="errors[0]"
                  :disabled="$wait.is('adding address')"
                  type="text"
                  name="contact_name"
                  class="md:tw-w-1/2 tw-mb-3 md:tw-mr-4"
                  data-test="contact-name"
                  ignore-optional
                />
              </template>
            </ValidationProvider>

            <template
              v-if="phoneCountry"
            >
              <ValidationProvider
                :name="$t('new-shipment.fields.contact.phone')"
                :rules="`telephone:${phoneCountry}`"
                slim
              >
                <template slot-scope="{ invalid, validated, errors }">
                  <ctk-phone-number
                    id="contact_phone"
                    ref="contact-phone"
                    v-model="phoneNumber"
                    :country.sync="phoneCountry"
                    :error="invalid && validated"
                    :hint="errors[0]"
                    :label="$t('new-shipment.labels.contact.phone')"
                    :disabled="$wait.is('adding address')"
                    name="contact_phone"
                    autocomplete="tel-national"
                    class="md:tw-w-1/2 tw-mb-3"
                    ignore-optional
                    data-test="contact-phone"
                  />
                </template>
              </ValidationProvider>
            </template>
          </div>
        </fieldset>

        <!-- Rendez-vous -->
        <ValidationObserver
          ref="meeting"
          slim
        >
          <new-shipment-address-meeting
            :comment.sync="formData.meeting.comment"
            :rdv.sync="formData.meeting.rendez_vous"
            data-test="meeting"
          />
        </ValidationObserver>

        <fieldset>
          <legend
            class="tw-text-base"
          >
            <span
              v-text="$t('new-shipment.titles.informations')"
            />
            <span
              v-text="`(${$options.filters.capitalize($t('optional'))})`"
              class="tw-text-xs tw-text-secondary-text tw-italic tw-ml-1"
            />
          </legend>

          <new-shipment-alert>
            <h3
              v-text="$t('new-shipment.titles.address_important_infos')"
              class="tw-text-sm tw-font-normal tw-text-secondary"
              data-test="infos-title"
            />
            <ul
              class="new-shipment-address-add__important-infos tw-mb-0"
              data-test="infos-list"
            >
              <li
                v-for="info in importantInfos"
                :key="info"
                :data-test="`infos-item-${info}`"
                v-text="$t(`new-shipment.paragraphs.address.${info}`)"
              />
            </ul>
          </new-shipment-alert>

          <ValidationProvider
            :name="$t('new-shipment.fields.address_important_infos')"
            rules="max:4000"
            slim
          >
            <template slot-scope="{ invalid, validated, errors }">
              <ctk-input-textarea
                id="comment"
                v-model="formData.comment"
                :label="$t('new-shipment.labels.address_infos')"
                :error="invalid && validated"
                :hint="invalid ? errors[0] : null"
                :disabled="$wait.is('adding address')"
                type="text"
                name="comment"
                class="tw-mb-12"
                has-optional-suffix
                ignore-optional
                @input="updateGuard"
              />
            </template>
          </ValidationProvider>
        </fieldset>

        <new-shipment-alert
          data-test="fees"
        >
          <p
            v-text="$t('new-shipment.paragraphs.address.extra_hours_fees')"
            class="tw-mb-0"
            data-test="extra-hours"
          />
          <p
            v-text="$t('new-shipment.paragraphs.meeting.option')"
            class="tw-mb-0"
            data-test="meeting-option"
          />
        </new-shipment-alert>
      </template>

      <div
        :class="{
          'is-floating': !(hadAddress || hasPrefilledAddress)
        }"
        class="tw-flex tw-flex-col-reverse 2sm:tw-justify-between 2sm:tw-flex-row new-shipment-address-add__buttons tw-mt-6"
      >
        <div
          class="tw-mt-4 2sm:tw-mt-0"
        >
          <ui-button
            variant="link"
            class="tw-w-full 2sm:tw-w-auto"
            type="button"
            data-test="back"
            @click="back"
          >
            <template #left-icon>
              <ui-material-icon
                name="keyboard_arrow_left"
              />
            </template>

            {{ $t('back') | capitalize }}
          </ui-button>
        </div>
        <ui-button
          v-if="hadAddress || hasPrefilledAddress"
          :loading="$wait.is('adding address')"
          :disabled="$wait.is('adding address')"
          data-test="save-button"
          variant="primary"

          type="submit"
        >
          {{ $t(`new-shipment.buttons.save_address.${direction}`) }}
        </ui-button>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import moment from 'moment'
  import { mapActions, mapGetters } from 'vuex'
  import {
    parsePhoneNumberFromString
  } from 'libphonenumber-js'

  import { i18n } from '@/locales'
  // @ts-ignore
  import useCountryLists from '@/composables/useCountryLists'
  import CtkInputText from '@/components/CtkInputs/CtkInputText/index.vue'
  import CtkInputTextarea from '@/components/CtkInputs/CtkInputTextarea/index.vue'
  import CtkPhoneNumber from '@/components/CtkPhoneNumber/index.vue'

  import AutocompleteAddressInput from './_subs/AutocompleteAddressInput/index.vue'
  import NewShipmentAddressAvailableCountries from './_subs/NewShipmentAddressAvailableCountries/index.vue'
  import NewShipmentAddressMeeting from './_subs/NewShipmentAddressMeeting/index.vue'
  import NewShipmentAlert from '@/views/Shippers/NewShipment/components/NewShipmentAlert/index.vue'

  import { EventBus } from '@/services/EventBus'
  import AddressComponent from '@/models/AddressComponent'

  /**
   * @module component - NewShipmentAddressAdd
   * @param {object} [prefilledAddress=null]
   */
  export default defineComponent({
    name: 'NewShipmentAddressAdd',
    components: {
      AutocompleteAddressInput,
      NewShipmentAddressAvailableCountries,
      CtkInputText,
      CtkPhoneNumber,
      CtkInputTextarea,
      NewShipmentAddressMeeting,
      NewShipmentAlert
    },
    props: {
      prefilledAddress: {
        type: Object,
        default: null
      }
    },
    beforeDestroy () {
      if (this.$refs.observer && this.$refs.observer.reset) this.$refs.observer.reset()
    },
    watch: {
      async prefilledAddress (v) {
        /**
         * Possibliy hide the company field
         */
        this.hasCompanyNameField = false

        if (!v) {
          /**
           * Quick fix to ensure the comment and the contact
           * infos filed is became empty if there is no prefilled address.
           * TODO: Find a better way to handle this edge case; a major
           * refacto may be needed.
           */
          this.formData.comment = null
          this.formData.contact = {
            name: null,
            phone: {
              number: null,
              country: 'FR'
            }
          }
        }
      }
    },
    data () {
      return {
        importantInfos: ['heavy_truck_access', 'hours', 'opening_hours'],
        fields: null,
        country: 'FR',
        isCountrySelectVisible: false,
        hasCompanyNameField: false,
        previousRoute: null,
        formData: {
          addressQuery: null,
          address: null,
          companyName: null,
          contact: {
            name: null,
            phone: {
              number: null,
              country: 'FR'
            }
          },
          comment: null,
          meeting: {
            /** @type {boolean|null} */
            rendez_vous: null,
            /** @type {string|null} */
            comment: null
          },
          handling: {
            pickup: {
              driver: null,
              tail_lift: null
            },
            delivery: {
              driver: null,
              tail_lift: null
            }
          },
          time_slot: {
            pickup: {
              startTime: null,
              endTime: null
            },
            delivery: {
              startTime: null,
              endTime: null
            }
          }
        },
        hadAddress: false
      }
    },
    computed: {
      ...mapGetters('shipments/new-shipment', [
        'getPickupAddress',
        'getDeliveryAddress',
        'getAddress',
        'getContact',
        'getComment',
        'getMeeting'
      ]),
      ...mapGetters('shipments', [
        'getShipmentsMetrics'
      ]),
      hasShipments () {
        if (!this.getShipmentsMetrics) return false

        const { counts } = this.getShipmentsMetrics
        return counts && !!counts.total && counts.total >= 1
      },
      phoneNumber: {
        get () {
          return this.formData.contact.phone && this.formData.contact.phone.number
        },
        set (v) {
          const { contact } = this.formData
          if (!contact.phone) {
            contact.phone = {
              number: null,
              country: this.phoneCountry
            }
          }
          contact.phone.number = v
        }
      },
      phoneCountry: {
        get () {
          return (this.formData.contact && this.formData.contact.phone && this.formData.contact.phone.country) || 'FR'
        },
        set (v) {
          const { contact } = this.formData
          if (!contact.phone) {
            contact.phone = {
              number: null,
              country: v
            }
          }

          contact.phone.country = v
        }
      },
      /**
       * Returns a list of disabled countries from the pickup & delivery address
       * data from store.
       * @function disabledCountries
       */
      disabledCountries () {
        const { getUnauthorizedSameCountries } = useCountryLists()
        const unauthorizedSameCountries = getUnauthorizedSameCountries()

        if (this.getPickupAddress && this.direction === 'delivery' && unauthorizedSameCountries.includes(this.getPickupAddress.country)) {
          return [this.getPickupAddress.country]
        }

        if (this.getDeliveryAddress && this.direction === 'pickup' && unauthorizedSameCountries.includes(this.getDeliveryAddress.country)) {
          return [this.getDeliveryAddress.country]
        }

        return []
      },
      direction () {
        return this.$route.params.direction
      },
      /**
       * Returns true if there is a prefilled address (either from the CTK prediction
       * or from an already selected address)
       * @function hasPrefilledAddress
       * @returns {boolean}
       */
      hasPrefilledAddress () {
        return this.prefilledAddress && !!this.prefilledAddress.address
      },
      /**
       * Returns true if the company field should be shown
       * @function hasCompanyField
       * @returns {boolean}
       */
      hasCompanyField () {
        const hasCompanyNameWithoutPrefilled = !this.hasPrefilledAddress && this.hasCompanyNameField
        const hasCompanyNameWithPrefilled = !!(this.hasPrefilledAddress && this.prefilledAddress.address.hadCustomName) && this.hasCompanyNameField

        return hasCompanyNameWithoutPrefilled ||
          hasCompanyNameWithPrefilled ||
          this.hasCompanyNameField
      }
    },
    async mounted () {
      await this.$nextTick()
      if (this.$refs.observer && this.$refs.observer.reset) this.$refs.observer.reset()
      this.updateFormAddress()
    },
    methods: {
      ...mapActions('shipments/new-shipment', [
        'setAddress',
        'setComment',
        'setMeeting',
        'setContact',
        'setTimeslot',
        'setHandlingDriver',
        'setHandlingTailLift',
        'setGuard'
      ]),
      selectCountry (country) {
        if (this.$refs.address && this.$refs.address.$refs['address-input']) {
          this.$refs.address.$refs['address-input'].countryComputed = country
          this.$refs.address.$refs['address-input'].resetPredictions()
        }
      },
      toggleCountry (v) {
        this.isCountrySelectVisible = v
      },
      /**
       * Called whenver the user clicks on the cancel button
       * @function cancel
       */
      cancel () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Quotations', 'Cancelled Address')
        }

        /**
         * Remove the error dropdown when the user resets the address field.
         * This is made here to avoid the "jump" of dropdowns (when the predictions
         * is shown along the error).
         * Note: this is also called on the reset event
         */
        this.setAddressNotPreciseError(false)

        EventBus.$emit('shipments:new-shipment:set-enriched-address', {
          direction: this.direction,
          address: null
        })
        this.setAddress({
          direction: this.direction,
          address: null
        })
        this.resetFormData()
        this.hadAddress = false
        this.updateGuard()
      },
      /**
       * Called whenever the user clicks on the back button
       * @function back
       */
      back () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Quotations', 'Clicked Back')
        }

        let route = {
          name: 'NewShipmentAddress',
          params: {
            direction: 'pickup'
          }
        }

        const { name, params } = this.$route

        if (name === 'NewShipmentAddress' && params.direction === 'pickup') {
          if (this.hasShipments) {
            route = {
              name: 'NewShipmentTemplate'
            }
          }

          /**
           * Verify if the previous route was the template list
           */
          if (this.$parent && this.$parent.previousRoute && this.$parent.previousRoute.name === 'NewShipmentTemplateList') {
            route = {
              name: 'NewShipmentTemplateList'
            }
          }
        }

        this.$router.push(route).catch(() => {})
      },
      /**
       * Update the form data with the props value
       * @function updateFormAddress
       */
      updateFormAddress () {
        if (this.hasPrefilledAddress) {
          const {
            address,
            comment,
            meeting,
            contact,
            handling,
            isGaAddress,
            start_time: startTime,
            end_time: endTime
          } = this.prefilledAddress

          const {
            name,
            postal_code: postalCode,
            city,
            street_name: streetName,
            location,
            country,
            hadCustomName
          } = address

          this.formData.companyName = name
          this.formData.address = {
            postalCode,
            streetName,
            city,
            location,
            country
          }

          /**
           * TODO: Get infos from the address and assign to the meeting option.
           */

          this.formData.contact = Object.assign({}, {
            name: null,
            phone: {
              number: null,
              country: null
            }
          }, contact)

          if (typeof this.formData.contact.phone === 'string') {
            this.formData.contact.phone = Object.assign({}, {
              number: this.formData.contact.phone,
              country: null
            })
          }

          this.formData.contact = JSON.parse(JSON.stringify(this.formData.contact))

          /**
           * Tries to re-parse the phone number to get the country back.
           * TODO: Find a more elegant way to handle the country across the entire app.
           */
          if (this.formData.contact.phone && this.formData.contact.phone.number && !this.formData.contact.phone.country) {
            const parsedPhone = parsePhoneNumberFromString(this.formData.contact.phone.number)
            if (parsedPhone) {
              this.formData.contact.phone.country = parsedPhone.country
            }
          }

          /**
           * Update the country phone number with the address country code
           * only if the phone number wasn't already prefilled.
           */
          if (this.formData.contact.phone && !this.formData.contact.phone.number) {
            this.formData.contact.phone.country = country
          }

          this.formData.comment = comment

          /**
           * If there is something in the meeting, assume the radio should be selected.
           * Note that the meeting here could be either a string (legacy) or the meeting object.
           * Handle both cases.
           *
           * Setting as null by default has we may not have the meeting option at all.
           */
          this.formData.meeting = {
            rendez_vous: null,
            comment: null
          }

          if (meeting) {
            if (typeof meeting === 'string') {
              this.formData.meeting = {
                rendez_vous: true,
                comment: meeting
              }
            } else {
              this.formData.meeting = {
                rendez_vous: meeting.rendez_vous,
                comment: meeting.comment
              }
            }
          }

          if (isGaAddress && hadCustomName) {
            this.hasCompanyNameField = true
          }

          /**
           * Update the address query with formatted address from prefilled
           */
          if (this.hasCompanyNameField) {
            this.formData.addressQuery = `${streetName}, ${city}, ${i18n.t(country)}`
          } else {
            this.formData.addressQuery = `${name ? `${name}, ` : ''}${streetName}, ${city}, ${i18n.t(country)}`
          }

          /**
           * Force the country to be updated in the input itself
           */
          if (this.$refs.address && this.$refs.address.$refs['address-input']) {
            this.$refs.address.$refs['address-input'].countryComputed = country
          }

          /**
           * Handling
           * Set the handling infos directly to the store, since we gonna prefill it in the handling
           * view.
           */
          /**
           * TODO: Remove the usage of 'carrier' & 'shipper'; use true / false instead.
           */
          this.formData.handling[this.direction].driver = handling.driver !== null
            ? (handling.driver ? 'carrier' : 'shipper')
            : null

          /**
           * TODO: Remove the usage of 'yes' & 'no' values; use true / false instead.
           */
          this.formData.handling[this.direction].tail_lift = handling.tail_lift !== null
            ? (handling.tail_lift ? 'yes' : 'no')
            : null

          /**
           * Timeslots
           * Set the timeslot infos to the store, since we gonna prefill
           * the sliders in the dates view
           */
          const startTimeMinutes = moment.duration(startTime).asMinutes()
          const endTimeMinutes = moment.duration(endTime).asMinutes()
          this.formData.time_slot[this.direction] = {
            startTime: startTimeMinutes,
            endTime: endTimeMinutes
          }
        } else {
          /**
           * Force the country to be updated in the input itself from the values
           * specified in the store, if available
           */
          const address = this.getAddress(this.direction)
          if (!address) {
            return false
          }

          if (address && address.country) {
            if (this.$refs.address && this.$refs.address.$refs['address-input']) {
              this.$refs.address.$refs['address-input'].countryComputed = address.country
            }
          }
        }
      },
      /**
       * Reset the form information whenever the user quits the form
       * @function resetFormData
       */
      resetFormData () {
        this.formData = {
          addressQuery: null,
          address: null,
          companyName: null,
          contact: {
            name: null,
            phone: null
          },
          meeting: {
            rendez_vous: null,
            comment: null
          },
          comment: null,
          handling: {
            pickup: {
              driver: null,
              tail_lift: null
            },
            delivery: {
              driver: null,
              tail_lift: null
            }
          },
          time_slot: {
            pickup: {
              startTime: null,
              endTime: null
            },
            delivery: {
              startTime: null,
              endTime: null
            }
          }
        }
      },
      setAddressNotPreciseError (v, cb) {
        const addressRef = this.$refs.address
        if (addressRef) {
          const input = addressRef.$refs['address-input']
          if (input) {
            if (cb) cb(input)
            input.hasError = v
          }
        }
      },
      setAddressNotCoveredError (v, cb) {
        const addressRef = this.$refs.address
        if (addressRef) {
          const input = addressRef.$refs['address-input']
          if (input) {
            if (cb) cb(input)
            input.hasError = v
          }
        }
      },
      resetAddress () {
        this.setAddressNotPreciseError(false)
        if (this.$refs['address-provider']) this.$refs['address-provider'].reset()

        this.formData.address = null
        this.hasCompanyNameField = false
        this.updateGuard()
      },
      /**
       * @typedef UpdateAddressOptions
       * @type {object}
       * @property {import('@/models/AddressComponent')} address
       * @property {any} source
       * @property {?string} companyName
       *
       * @function updateAddress
       * @param {UpdateAddressOptions} options
       */
      updateAddress ({ address, source, companyName }) {
        const { isCountryOpened } = useCountryLists()

        /**
         * Reset any possible errors in the address field after prediction selection
         */
        this.$refs.observer.reset()

        /**
         * Check if the address selected by the user is not precise enough
         * (if any of the required components are not present)
         */
        if (address instanceof AddressComponent && !address.hasRequiredComponents) {
          this.setAddressNotPreciseError(true, (input) => {
            input.predictionTries = 3
            input.predictions = []
            input.isDirty = true
            input.onFocus()
          })
        }

        this.formData.address = address
        this.formData.companyName = companyName

        /**
         * Force the country to be changed whenever the user selects a prediction in the list
         * to match.
         */
        const { country } = this.formData.address

        if (country && !isCountryOpened(country)) {
          this.$refs['address-provider'].setErrors([
            'Pays pas couvert par Chronotruck'
          ])
          this.$refs.observer.setErrors({
            adresse: 'Ce Pays n\'est pas couvert par Chronotruck'
          })
          this.setAddressNotCoveredError(true)

          return
        }

        if (this.$refs.address && this.$refs.address.$refs['address-input']) {
          this.$refs.address.$refs['address-input'].countryComputed = country
        }

        /**
         * Set the value to true when a first address is selected so the vue
         * is not reset.
         * @var hadAddress
         */
        this.hadAddress = true

        /**
         * Emit a select-address event to consider the CTK prediction value
         * as a enrichedAddress, and prefill all the fields.
         */
        if (source) {
          const enrichedAddress = source
          enrichedAddress.isCtkAddress = true

          this.$emit('select-address', {
            direction: this.direction,
            enrichedAddress
          })
        }

        /**
         * If there isn't a company name from the autocomplete, show the company
         * name field
         */
        this.hasCompanyNameField = false
        if (!companyName) {
          this.$nextTick(() => {
            this.hasCompanyNameField = true
          })
        }

        const { streetName, city } = this.formData.address
        /**
         * Update the address query with formatted address from prefilled
         */
        if (this.hasCompanyNameField) {
          this.formData.addressQuery = `${streetName ? `${streetName}, ` : ''}${city}, ${i18n.t(country)}`
        } else {
          this.formData.addressQuery = `${companyName ? `${companyName}, ` : ''}${streetName ? `${streetName}, ` : ''}${city}, ${i18n.t(country)}`
        }

        /**
         * Update the country phone number with the address country code
         * only if the phone number wasn't already prefilled.
         */
        if (this.formData.contact.phone && !this.formData.contact.phone.number) {
          this.formData.contact.phone.country = country
        }

        this.updateGuard()
      },
      /**
       * Called whenever the user changes something in the addresses
       * to update the guards.
       * @function updateGuard
       */
      updateGuard () {
        const {
          address,
          companyName,
          comment,
          contact,
          meeting
        } = this.formData

        if (!address || !companyName) {
          return false
        }

        const addressToSave = {
          name: companyName,
          street_name: address.streetName || companyName,
          postal_code: address.postalCode,
          city: address.city,
          country: address.country,
          location: {
            lat: address.location.lat,
            lng: address.location.lng
          }
        }

        const alreadySavedAddress = this.getAddress(this.direction)
        const alreadySavedComment = this.getComment(this.direction)
        const alreadySavedMeeting = this.getMeeting(this.direction)
        const alreadySavedContact = this.getContact(this.direction)

        const hasCommentChanged = alreadySavedComment && comment !== alreadySavedComment
        const hasMeetingChanged = alreadySavedMeeting && meeting !== alreadySavedMeeting
        const hasContactChanged = alreadySavedContact && contact !== alreadySavedContact
        const hasAddressChanged = alreadySavedAddress && addressToSave !== alreadySavedAddress

        if (hasAddressChanged || hasCommentChanged || hasMeetingChanged || !alreadySavedAddress || hasContactChanged) {
          const guards = ['dates', 'handling', 'goods']
          if (this.direction === 'pickup') {
            guards.push('pickup_address')
            guards.push('delivery_address')
          } else {
            guards.push('delivery_address')
          }

          guards.forEach(guard => this.setGuard({
            guard,
            value: false
          }))
        }
      },
      async submitted () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Quotations', 'Validated Address')
        }

        await this.$nextTick()

        /**
         * Validate RDV.
         * Note: the validation observer is inside a child component
         * and therefore we cannot validate it directly through our local observer.
         * Must call the nested one.
         */
        // @ts-ignore
        const isRdvValid = await this.$refs.meeting?.validate()
        if (!isRdvValid) return

        this.$refs.observer.reset()
        this.$refs.observer.validate()
          .then(valid => {
            if (!valid) return false

            const direction = this.direction
            const {
              address,
              companyName,
              contact,
              comment,
              time_slot: timeSlot,
              meeting
            } = this.formData

            if (!address) {
              this.$refs['address-provider'].setErrors([
                this.$t('new-shipment.paragraphs.error.select-address')
              ])
              return false
            }

            if (address && !!address.component && !address.hasRequiredComponents) {
              /**
               * The address picked seems not precise enough, there are missing parts
               * of the address. Force the dropdown to be shown with the not precise
               * enough message.
               */
              this.setAddressNotPreciseError(true, (input) => {
                input.predictionTries = 3
                input.predictions = []
                input.isDirty = true
                input.onFocus()
              })
              return false
            }

            this.$wait.start('adding address')

            const addressToSave = {
              name: companyName,
              street_name: address.streetName || companyName,
              postal_code: address.postalCode,
              city: address.city,
              country: address.country,
              location: {
                lat: address.location.lat,
                lng: address.location.lng
              },
              hadCustomName: this.hasCompanyNameField
            }

            /**
             * Check if the address has changed, if it does then disable
             * all the following steps.
             */
            this.updateGuard()

            this.setAddress({
              direction,
              address: addressToSave
            })

            this.setContact({
              direction,
              contact
            })

            this.setComment({
              direction,
              comment
            })

            this.setMeeting({
              direction,
              meeting
            })

            if (timeSlot && timeSlot[this.direction] && timeSlot[this.direction].startTime) {
              this.setTimeslot({
                direction: this.direction,
                startTime: timeSlot[this.direction].startTime,
                endTime: timeSlot[this.direction].endTime
              })
            }

            this.$wait.end('adding address')

            const isDelivery = direction === 'delivery'
            this.setGuard({
              guard: isDelivery ? 'delivery_address' : 'pickup_address',
              value: true
            })
            this.$router.push({
              name: isDelivery
                ? 'NewShipmentGoods'
                : 'NewShipmentAddress',
              params: {
                direction: !isDelivery ? 'delivery' : null
              }
            })
              .catch(() => {})

            /**
             * When changing view, reset all the necessary fields.
             */
            this.$refs.observer.reset()
            this.resetFormData()
            this.hasCompanyNameField = false
            this.hadAddress = false

            if (this.$refs.address) {
              this.$refs.address.isDirty = false
              this.$refs.address.resetPredictions()
            }
          })
      }
    }
  })
</script>

<style lang="scss" scoped>
.new-shipment-address-add__title {
  position: relative;
  font-size: 20px;
}
.new-shipment-address-add__title::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 220px;
  height: 1px;
  background-color: $divider;
}
.new-shipment-address-add__important-infos {
  list-style-type: none;
  padding-left: 0.75rem;
}
.new-shipment-address-add__important-infos li {
  position: relative;
}
.new-shipment-address-add__important-infos li::before {
  position: absolute;
  content: '•';
  color: $info;
  font-size: 20px;
  left: -0.75rem;
  top: -4px;
}
.new-shipment-address-add__buttons.is-floating {
  position: absolute;
  bottom: 30px;
}
.new-shipment-address-add .new-shipment-address-available-countries {
  margin-left: -40px;
  margin-top: -1rem;
  margin-right: -40px;
  margin-bottom: -30px;
}
@media only screen and (max-width: $breakpoint-tablet) {
  .new-shipment-address-add .new-shipment-address-available-countries .new-shipment-address-available-countries-list {
    position: relative;
  }
  .new-shipment-address-add .new-shipment-address-available-countries {
    margin: 0;
    flex-direction: column;
  }
  .new-shipment-address-add, .new-shipment-address-add .new-shipment-address-available-countries {
    flex: 0;
  }
}
</style>
