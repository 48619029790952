import { computed, reactive, readonly, ref } from '@vue/composition-api'

import { Offer } from '@/resources'
import { icons, fitBoundsOptions } from '@/services/Leaflet'

import useStore from '@/composables/useStore'

export default function useMap () {
  const store = useStore()

  const defaultMapData = {
    zoom: 5,
    maxZoom: 14,
    minZoom: 4,
    options: {
      zoomControl: false,
      keyboard: false
    },
    controlZoomPosition: 'bottomright',
    center: [48.864716, 2.349014],
    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attribution">CARTO</a>',
    url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png'
  }

  const mapData = readonly(Object.assign({}, defaultMapData))
  const line = ref(null)
  const map = ref(null)
  const markers = ref(null)
  const itinerary = reactive({
    timeout: null,
    visible: false,
    latlngs: null,
    style: {
      color: '#1D4289',
      opacity: 1,
      weight: 3
    }
  })
  const popupOptions = readonly({
    closeButton: false
  })

  function updateItineraryCoords (coordinates) {
    itinerary.latlngs = coordinates
      .map(location => ([
        location[1],
        location[0]
      ]))

    setTimeout(() => {
      zoomToMarkers()
    }, 1500)
  }

  /**
   * Returns the itinerary from the API or the store, if previously fetched.
   * @function retrieveItinerary
   * @param {object} offer - The offer object
   */
  const retrieveItinerary = offer => {
    const currentOffer = store.value.getters['offers/getCurrentOffer']
    const itineraries = store.value.getters['offers/getOffersItineraries']
    const exists = itineraries.find(e => e.uuid === offer.uuid)

    removeLine()

    if (exists) {
      updateItineraryCoords(exists.itinerary.coordinates)
    } else {
      /**
       * Retrive the offer only if the offer is available
       */
      if (!(typeof offer.available_for_booking !== 'undefined' && offer.available_for_booking === false)) {
        Offer.travel({
          cid: store.value.getters['auth/getCid'],
          oid: offer.uuid
        })
          .then((res) => {
            if (res.data.itinerary) {
              const { itinerary, distance, duration } = res.data

              /**
               * Update the travel informations
               */
              store.value.dispatch('offers/setCurrentOffer', {
                ...currentOffer,
                travel: {
                  distance,
                  duration
                }
              })

              store.value.dispatch('offers/pushItinerary', {
                uuid: offer.uuid,
                itinerary
              })

              /**
               * Force the itinerary change only if the offer didn't change during
               * the request.
               */
              if (currentOffer && currentOffer.uuid === offer.uuid) {
                updateItineraryCoords(itinerary.coordinates)
              }
            }
          })
          .catch((err) => {
            console.log('Error occured while retrieving this itinerary', err)
          })
      }
    }
  }

  /**
   * Remove the line itinerary from the map
   * @function removeLine
   */
  const removeLine = () => {
    itinerary.visible = false
    itinerary.latlngs = null
    if (itinerary.timeout) {
      clearTimeout(itinerary.timeout)
      itinerary.timeout = null
    }
  }

  const getPickupIcon = computed(() => {
    return icons.pickup('in_progress')
  })

  const getDeliveryIcon = computed(() => {
    return icons.delivery('in_progress')
  })

  const hasLocation = computed(() => {
    const offer = store.value.getters['offers/getCurrentOffer']
    return offer && offer.pickup && offer.delivery && offer.pickup.address.location && offer.delivery.address.location
  })

  const hasMapObject = computed(() => {
    return map.value && map.value.mapObject
  })

  /**
   * Zoom to the marker's (or itinerary if available) boundaries
   * @function zoomToMarkers
  */
  function zoomToMarkers () {
    if (hasLocation.value && hasMapObject.value) {
      try {
        if (!map.value || !markers.value) return

        const mapObject = map.value.mapObject
        const markersObject = markers.value.mapObject
        if (mapObject && markersObject) {
          mapObject.flyToBounds(markersObject.getBounds(), fitBoundsOptions)

          itinerary.timeout = setTimeout(() => {
            itinerary.visible = true
          }, fitBoundsOptions.duration * 1000)
        }
      } catch (e) {
        console.error('Could not zoom to the marker boundaries.', e)
      }
    }
  }

  /**
   * Returns a formatted address string
   * @function formatAddress
   * @param {object} address - Address object from a single offer
   * @param {string} direction
   * @returns {string} formattedAddress
   */
  const formatAddress = (address, direction) => {
    return `
      <div class="header ${direction}">
        <b>${address.city}</b><br />
        ${address.postal_code}, ${address.country}
      </div>
    `
  }

  return {
    map,
    markers,
    mapData,
    line,
    formatAddress,
    zoomToMarkers,
    popupOptions,
    hasLocation,
    getPickupIcon,
    getDeliveryIcon,
    itinerary,
    retrieveItinerary
  }
}
