<template>
  <div class="new-shipment-quotation">
    <new-shipment-sidebar
      class="new-shipment-sidebar__embedded"
    />

    <div class="tw-flex tw-justify-between tw-items-start">
      <h2
        class="new-shipment-quotation__title tw-font-normal"
        data-test="title"
        v-text="$t('new-shipment.titles.quotation')"
      />
      <ui-button
        :rounded="false"
        variant="info"
        type="button"
        data-test="compare"
        @click="dialogs.compare = true"
      >
        {{ $t(getQuotation && getQuotation.prices && getQuotation.prices.length > 1
          ? 'shipments.titles.price_compare'
          : 'shipments.titles.price_understanding') }}
      </ui-button>
    </div>

    <template
      v-if="!!getPalletNetworkPrice"
    >
      <new-shipment-quotation-price
        v-if="getQuotation && getPrice"
        :title="offers[getPrice.type]"
        :lines="lines"
        :value="selectedPrice && (selectedPrice.type === 'chronotruck' || selectedPrice.type === 'custom')"
        :price-type="'chronotruck'"
        :has-check="true"
        :total-vat-included="`${$t('price_incl_tax', {
          price: $options.filters.currency(getPrice.total_including_vat, getPrice.currency, $i18n.locale)
        })}${priceSurge ? '*' : ''}`"
        :total-vat-excluded="`${$t('price_excl_tax', {
          price: $options.filters.currency(getPrice.total, getPrice.currency, $i18n.locale)
        })}${priceSurge ? '*' : ''}`"
        :min-price="getCustomOptionsPrice"
        :open.sync="detailOpen.chronotruck"
        class="tw-mb-5"
        :data-test="`${getPrice.type}-price`"
        @input="selectPrice(getPrice)"
        @is-custom-price-selected="handleCustomPriceState"
      >
        <new-shipment-quotation-price-content
          :offer-type="getPrice.type"
          :is-custom="isCustomOffer"
          @learn-more="learnMore"
        />

        <template
          v-if="priceSurge"
          #price-surge
        >
          <p
            class="tw-mt-4 tw-italic tw-text-xs tw-text-gray-700"
            v-matomo="{
              insert: {
                category: 'Quotations',
                action: 'Display Price Increase Sentence',
                name: priceSurge
              }
            }"
            v-text="`* ${priceSurgeExplanation}`"
            data-test="price-surge"
          />
        </template>
      </new-shipment-quotation-price>
      <new-shipment-quotation-price
        v-if="getQuotation && getPalletNetworkPrice"
        :title="offers.pallet_network"
        :lines="palletNetworkLines"
        :value="selectedPrice && selectedPrice.type === 'pallet_network'"
        :price-type="'pallet_network'"
        :has-check="true"
        :total-vat-included="`${$t('price_incl_tax', {
          price: $options.filters.currency(getPalletNetworkPrice.total_including_vat, getPalletNetworkPrice.currency, $i18n.locale)
        })}`"
        :total-vat-excluded="`${$t('price_excl_tax', {
          price: $options.filters.currency(getPalletNetworkPrice.total, getPalletNetworkPrice.currency, $i18n.locale)
        })}`"
        :open.sync="detailOpen.pallet_network"
        data-test="pallet-network-price"
        @input="selectPrice(getPalletNetworkPrice)"
        @click.native="isCustomOffer = false"
      >
        <new-shipment-quotation-price-content
          :offer-type="getPalletNetworkPrice.type"
          @learn-more="learnMore"
        />
      </new-shipment-quotation-price>
    </template>
    <template
      v-else
    >
      <new-shipment-quotation-price
        v-if="getQuotation && getPrice"
        :title="offers[getPrice.type]"
        :lines="lines"
        :value="true"
        :price-type="'chronotruck'"
        :has-check="false"
        :total-vat-included="`${$t('price_incl_tax', {
          price: $options.filters.currency(getPrice.total_including_vat, getPrice.currency, $i18n.locale)
        })}${priceSurge ? '*' : ''}`"
        :total-vat-excluded="`${$t('price_excl_tax', {
          price: $options.filters.currency(getPrice.total, getPrice.currency, $i18n.locale)
        })}${priceSurge ? '*' : ''}`"
        :min-price="getCustomOptionsPrice"
        :open.sync="detailOpen.chronotruck"
        @is-custom-price-selected="handleCustomPriceState"
        data-test="price"
      >
        <new-shipment-quotation-price-content
          :offer-type="getPrice.type"
          :is-custom="isCustomOffer"
          @learn-more="learnMore"
        />

        <template
          v-if="priceSurge"
          #price-surge
        >
          <p
            class="tw-mt-4 tw-italic tw-text-xs tw-text-gray-700"
            v-matomo="{
              insert: {
                category: 'Quotations',
                action: 'Display Price Increase Sentence',
                name: priceSurge
              }
            }"
            v-text="`* ${priceSurgeExplanation}`"
            data-test="price-surge"
          />
        </template>
      </new-shipment-quotation-price>
    </template>

    <new-shipment-quotation-compare-dialog
      v-model="dialogs.compare"
      data-test="compare-dialog"
      @select-price="pickPrice"
    />

    <new-shipment-quotation-learn-more-dialog
      v-model="dialogs.learnMore.visible"
      :price-type="dialogs.learnMore.priceType"
      data-test="learn-more-dialog"
    />

    <div
      class="new-shipment-quotation__buttons tw-flex tw-flex-col-reverse 2sm:tw-flex-row 2sm:tw-justify-between tw-mt-6 tw-mb-4"
      data-test="buttons"
    >
      <div
        class="tw-mt-4 2sm:tw-mt-0"
      >
        <ui-button
          :to="{
            name: 'NewShipmentDates'
          }"
          variant="link"
          class="tw-w-full 2sm:tw-w-auto"
          data-test="back"
          @click.native="back"
        >
          <template #left-icon>
            <ui-material-icon
              name="keyboard_arrow_left"
            />
          </template>

          {{ $t('back') | capitalize }}
        </ui-button>
      </div>
      <ui-button
        data-test="save-button"
        variant="primary"
        type="button"
        :loading="$wait.is('fetching company infos')"
        :disabled="(!!getPalletNetworkPrice && !selectedPrice || (isSelectedPriceCustom && !getCustomPrice)) || $wait.is('fetching company infos')"
        @click="handleConfirmPrice()"
      >
        {{ $t('new-shipment.buttons.accept_quotation') }}
      </ui-button>
    </div>

    <new-shipment-quotation-actions
      class="tw-w-full"
    />

    <new-shipment-quotation-custom-price-warning-dialog
      :is-opened="openCustomPriceWarningDialog"
      :has-actions="true"
      :has-price="false"
      @on-close="openCustomPriceWarningDialog = false"
      @on-confirm="handleConfirmCustomPrice()"
    />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import store from '@/store'
  import Hotjar from '@/plugins/VueHotjar'

  import { offers } from '@/../config/chronoinfos'
  import NewShipmentQuotationCompareDialog from './components/NewShipmentQuotationCompareDialog'
  import NewShipmentQuotationLearnMoreDialog from './components/NewShipmentQuotationLearnMoreDialog'
  import NewShipmentQuotationPrice from './components/NewShipmentQuotationPrice'
  import NewShipmentQuotationPriceContent from './components/NewShipmentQuotationPrice/_subs/NewShipmentQuotationPriceContent'
  import NewShipmentQuotationActions from './components/NewShipmentQuotationActions'
  import NewShipmentSidebar from './../../components/NewShipmentSidebar'
  import NewShipmentQuotationCustomPriceWarningDialog from './components/NewShipmentQuotationCustomPriceWarningDialog'

  /**
   * @module view - NewShipmentQuotation
   */
  export default {
    name: 'NewShipmentQuotation',
    components: {
      NewShipmentQuotationCustomPriceWarningDialog,
      NewShipmentSidebar,
      NewShipmentQuotationPriceContent,
      NewShipmentQuotationCompareDialog,
      NewShipmentQuotationActions,
      NewShipmentQuotationLearnMoreDialog,
      NewShipmentQuotationPrice
    },
    beforeRouteEnter (to, from, next) {
      /**
       * Check if the user has completed everything
       */
      if (!store.getters['shipments/new-shipment/isGoodsCompleted']) {
        next({
          name: 'NewShipmentGoods'
        })
        return false
      }

      next()
    },
    data () {
      return {
        offers,
        dialogs: {
          compare: false,
          learnMore: {
            visible: false,
            priceType: 'chronotruck'
          }
        },
        isCustomOffer: false,
        selectedPrice: null,
        detailOpen: {
          chronotruck: false,
          pallet_network: false
        },
        openCustomPriceWarningDialog: false
      }
    },
    computed: {
      ...mapGetters('auth', [
        'getCid',
        'isFrenchShipper',
        'getFeatureFlags'
      ]),
      ...mapGetters('shipments/new-shipment', [
        'getQuotation',
        'getQuotationSelectedPrice',
        'getCustomPrice',
        'getPickupAddress',
        'getDeliveryAddress',
        'getPickupHandlingTailLift',
        'getDeliveryHandlingTailLift',
        'getPickupHandlingDriver',
        'getDeliveryHandlingDriver',
        'getLoad'
      ]),
      /**
       * Returns the price lines for the "chronotruck" offer only
       * @function lines
       * @returns {Array<object>}
       */
      lines () {
        const { lines: priceLines, currency } = this.getPrice

        return [
          ...priceLines.map((/** @type {object} */ { key, label, price }) => ({
            value: this.$options.filters.currency(price, currency, this.$i18n.locale),
            key,
            label
          }))
        ]
      },
      /**
       * Returns the price lines for the pallet network offer
       * @function palletNetworkLines
       * @returns {Array<object>}
       */
      palletNetworkLines () {
        const { lines: priceLines, currency } = this.getPalletNetworkPrice

        const hasTailLift = this.getPickupHandlingTailLift === 'yes' ||
          this.getDeliveryHandlingTailLift === 'yes'

        const hatchLine = [
          {
            key: 'hatch',
            label: this.$t('app.paragraphs.hatch_included'),
            value: this.$t('app.labels.included')
          }
        ]

        const pickupHandlingLine = [
          {
            key: 'pickup-handling',
            label: this.$t('app.paragraphs.pickup_handling_included'),
            value: this.$t('app.labels.included')
          }
        ]

        const deliveryHandlingLine = [
          {
            key: 'delivery-handling',
            label: this.$t('app.paragraphs.delivery_handling_included'),
            value: this.$t('app.labels.included')
          }
        ]

        return [
          ...priceLines.map((/** @type {object} */ { key, label, price }) => ({
            value: this.$options.filters.currency(price, currency, this.$i18n.locale),
            key,
            label
          })),
          ...(hasTailLift ? hatchLine : []),
          ...(this.getPickupHandlingDriver === 'carrier' ? pickupHandlingLine : []),
          ...(this.getDeliveryHandlingDriver === 'carrier' ? deliveryHandlingLine : [])
        ]
      },
      /**
       * Returns the pallet network price, if available
       * @function getPalletNetworkPrice
       * @returns {object|null}
       */
      getPalletNetworkPrice () {
        return this.getQuotation && this.getQuotation.prices.find(price => price.type === 'pallet_network')
      },
      /**
       * Returns either the Chronotruck price, if available
       * @function getPrice
       * @returns {object}
       */
      isSelectedPriceCustom () {
        if (this.getQuotationSelectedPrice) {
          return this.getQuotationSelectedPrice.type === 'custom'
        }
        return false
      },

      /**
       * Returns either the Chronotruck price, if available
       * @function getPrice
       * @returns {object}
       */
      getPrice () {
        return this.getQuotation && this.getQuotation.prices.find(price => price.type === 'chronotruck')
      },
      /**
       * Returns either the Custom price informations, if available
       * @function getCustomOfferPrice
       * @returns {object}
       */
      getCustomOfferPrice () {
        return this.getQuotation && this.getQuotation.prices.find(price => price.type === 'custom')
      },
      /**
       * Returns the Custom options price, if available
       * @function getCustomOptionsPrice
       * @returns {object}
       */
      getCustomOptionsPrice () {
        return this.getCustomOfferPrice ? this.getCustomOfferPrice.minimum_custom_price : 0
      },
      /**
       * Returns the quotation price surge
       * @function priceSurge
       * @returns {string|null}
       */
      priceSurge () {
        const price = this.getPrice
        return price && price.price_surge
      },
      /**
       * Returns the translated price surge explanation wording
       * @function priceSurgeExplanation
       * @returns {string}
       */
      priceSurgeExplanation () {
        const PRICE_SURGES = {
          emergency: 'new-shipment.paragraphs.quotation.price_surge.emergency',
          pickup_delivery_delay: 'new-shipment.paragraphs.quotation.price_surge.pickup_delivery_delay'
        }

        return this.$t(PRICE_SURGES[this.priceSurge])
      },
      /**
       * Returns true if the shipment is eligible to the pallet network.
       * @function hasPalletNetwork
       * @returns {boolean}
       */
      hasPalletNetwork () {
        if (!this.getQuotation) return false

        const index = this.getQuotation.prices.findIndex(price => price.type === 'pallet_network')
        return index !== -1
      }
    },
    mounted () {
      const trackingEvents = []
      if (this.hasPalletNetwork) trackingEvents.push('Display Pallet Network Sentence')

      trackingEvents.forEach(trackingEvent => {
        if (this.$matomo) {
          this.$matomo.trackEvent('Quotations', trackingEvent)
        }

        Hotjar.tag(trackingEvent)
      })

      /**
       * Case where the user comes back from the informations view
       * with the already-selected price
       */
      if (this.getQuotationSelectedPrice) {
        if (this.getQuotationSelectedPrice.type === 'custom') {
          this.selectedPrice = this.getPrice
        } else {
          this.selectedPrice = this.getQuotationSelectedPrice
        }
      }

      /**
       * Case where there is only the chronotruck price, set the price lines open
       * by default and select it
       */
      if (!this.getPalletNetworkPrice) {
        this.detailOpen.chronotruck = true
        this.pickPrice('chronotruck')
      }
    },
    methods: {
      ...mapActions('auth', [
        'retrieveCompany'
      ]),
      ...mapActions('shipments', [
        'retrieveShipmentsMetrics'
      ]),
      ...mapActions('shipments/new-shipment', [
        'retrievePhoneNumber',
        'setQuotationValidated',
        'setQuotationSelectedPrice',
        'setCustomPrice'
      ]),
      /**
       * Open the learn more dialog for a specific price type
       * @function learnMore
       * @param {string} priceType
       */
      learnMore (priceType) {
        this.dialogs.learnMore = {
          visible: true,
          priceType
        }
      },
      /**
       * Open the custom offer dialog
       * @function handleCustomPriceState
       */
      handleCustomPriceState (newState) {
        this.isCustomOffer = newState
        this.setCustomPrice(null)
        if (this.isCustomOffer === false) {
          this.pickPrice('chronotruck')
        } else {
          this.pickPrice('custom')
        }
      },
      /**
       * Called from the compare dialog to pick either the chronotruck or the pallet
       * network price.
       * @function pickPrice
       * @param {string} priceType
       */
      pickPrice (priceType) {
        switch (priceType) {
        case 'custom':
          this.selectPrice(this.getCustomOfferPrice)
          break
        case 'pallet_network':
          this.selectPrice(this.getPalletNetworkPrice)
          break
        case 'chronotruck':
          this.selectPrice(this.getPrice)
          break
        default:
          this.selectPrice(this.getPrice)
        }
      },
      /**
       * @function selectPrice
       * @param {object} priceType
       */
      selectPrice (priceType) {
        this.selectedPrice = priceType
        this.setQuotationSelectedPrice(priceType)
      },
      /**
       * Called whenever the user presses the back button
       * @function back
       */
      back () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Quotations', 'Clicked Back')
        }
      },

      handleConfirmPrice () {
        if (this.isSelectedPriceCustom && (this.getCustomPrice < this.getQuotationSelectedPrice.total)) {
          this.openCustomPriceWarningDialog = true
          this.$matomo.trackEvent('Custom offer', 'Custom offer warning modal opened')
          return
        }

        this.nextStep()
      },

      handleConfirmCustomPrice () {
        this.openCustomPriceWarningDialog = false
        this.nextStep()
      },

      nextStep () {
        const price = this.selectedPrice

        if (this.$matomo) {
          this.$matomo.trackEvent('Quotations', 'Validated Quotation', `${price.type}|${price.payment_plans[0]}`)
        }

        this.$wait.start('fetching company infos')

        Promise.all([
          this.retrieveCompany(),
          this.retrievePhoneNumber(),
          this.retrieveShipmentsMetrics()
        ])
          .finally(() => {
            this.$wait.end('fetching company infos')

            this.setQuotationSelectedPrice(this.selectedPrice)
            this.setQuotationValidated()

            this.$router.push({
              name: 'NewShipmentInformations'
            })
              .catch(() => {})
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
.new-shipment-quotation__title {
  position: relative;
  font-size: 20px;
  margin-bottom: 36px;
}
.new-shipment-quotation__title::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  height: 1px;
  background-color: $divider;
  width: 80px;
}
@media (min-width: 770px) {
  .new-shipment-quotation__title::after {
    width: 220px;
  }
}
@media only screen and (min-width: $breakpoint-tablet) {
  .new-shipment-quotation .new-shipment-sidebar {
    display: none;
  }
}
</style>
