var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-mb-4 tw-pt-4 new-shipment-goods-pallet-total"},[_c('div',{staticClass:"tw-flex tw-flex-col 2sm:tw-flex-row md:tw-w-3/4"},[_c('ValidationProvider',{ref:"height-provider",attrs:{"name":_vm.$t('new-shipment.fields.goods.height'),"rules":'required|min_value:1|max_value:270|numeric',"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-mb-4 2sm:tw-mr-4 2sm:tw-mb-0 tw-flex-1",attrs:{"id":"height","error":invalid && validated || errors && errors.length > 0,"label":_vm.$t('new-shipment.labels.goods.height_total'),"disabled":_vm.$wait.is('requesting quotation'),"name":"height","type":"tel","suffix":"cm","required":""},on:{"input":_vm.updateGuard},model:{value:(_vm.heightComputed),callback:function ($$v) {_vm.heightComputed=$$v},expression:"heightComputed"}})]}}])}),_c('ValidationProvider',{ref:"weight-provider",attrs:{"name":_vm.$t('app.labels.load.weight'),"rules":'required|min_value:50|max_value:28000|numeric',"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-mb-4 2sm:tw-mr-4 2sm:tw-mb-0 tw-flex-1",attrs:{"id":"weight","error":invalid && validated || errors && errors.length > 0,"label":_vm.$t('new-shipment.labels.goods.weight_total'),"disabled":_vm.$wait.is('requesting quotation'),"name":"weight","type":"tel","suffix":"kg","required":""},on:{"input":_vm.updateGuard},model:{value:(_vm.weightComputed),callback:function ($$v) {_vm.weightComputed=$$v},expression:"weightComputed"}})]}}])}),_c('div',{staticClass:"new-shipment-goods-pallet-total__lm tw-ml-auto 2sm:tw-ml-0 tw-px-4 tw-text-blue-500 tw-text-center",domProps:{"textContent":_vm._s(_vm.$t('lm', {
        lm: _vm.getPalletsMpl || '-'
      }))}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }