import useCountryLists from '@/composables/useCountryLists'
import useStore from '@/composables/useStore'

const useAdValorem = () => {
  const { value: store } = useStore()
  const { getAdValoremEligibleCountries } = useCountryLists()

  // * Checks if the user's company VAT number prefix (the n letters at its begining) matches one of the countries elgible to the ad valorem insurance
  const isAdValoremInsuranceEligible = () => {
    const eligibleCountries = getAdValoremEligibleCountries()
    const vatNumber = store.getters['auth/getVatNumber']

    // * Checks whether the VAT number starts with one of the eligible country identifiers
    return eligibleCountries.some(country => vatNumber.startsWith((country.vat_prefix || country['iso-2'])))
  }

  return {
    isAdValoremInsuranceEligible
  }
}

export default useAdValorem
