<template>
  <div
    :class="{
      'new-shipment-quotation-price--active': value
    }"
    class="new-shipment-quotation-price tw-rounded"
  >
    <component
      :is="hasCheck ? 'button' : 'div'"
      :type="hasCheck ? 'button': null"
      class="new-shipment-quotation-price__header tw-relative tw-flex tw-items-center tw-w-full tw-border-none tw-text-white tw-text-base tw-px-4 md:tw-px-6 tw-justify-between focus:tw-outline-none"
      data-test="header"
      @click="$emit('input')"
    >
      <new-shipment-quotation-price-header
        :active="value"
        :has-check="hasCheck"
        :title="title"
        data-test="header-content"
      />
    </component>

    <div>
      <div
        v-if="hasAccessibilityIssues && !hasPalletNetworkPriceType"
        class="new-shipment-quotation-price__accessibility-warning tw-flex tw-items-start tw-px-4 md:tw-px-6 tw-pt-5 md:tw-pt-8 tw-text-secondary-text tw-leading-16px tw-italic"
      >
        <ui-ctk-icon
          v-b-tooltip.hover
          name="warning-full"
          :class="`tw-text-${accessibilityWarningIconColor}-500 tw-text-26px tw--mt-3px`"
          data-test="accessibility"
        />
        <p v-text="accessibilityWarningMessage" />
      </div>
      <div class="new-shipment-quotation-price__content tw-flex tw-flex-col md:tw-flex-row tw-px-4 md:tw-px-6 tw-pt-5 md:tw-pt-8">
        <div class="new-shipment-quotation-price__content__inner tw-mb-2 md:tw-mb-0">
          <slot />
        </div>
        <div class="new-shipment-quotation-price__content__price">
          <div class="tw-flex tw-flex-col tw-text-right tw-items-end">
            <div class="tw-flex tw-justify-end tw-items-center">
              <ui-ctk-icon
                v-if="hasAccessibilityIssues && !hasPalletNetworkPriceType"
                v-b-tooltip.hover
                name="warning-full"
                :class="`tw-text-${accessibilityWarningIconColor}-500 tw-text-26px`"
                data-test="accessibility"
              />
              <div
                v-text="totalVatExcluded"
                class="tw-font-bold tw-text-xl"
                :class="{ 'tw-text-secondary-text' : isCustomPriceSelected }"
                data-test="total-vat-excluded"
              />
            </div>
            <div
              v-text="totalVatIncluded"
              class="tw-text-sm tw-italic"
              :class="{ 'tw-text-secondary-text' : isCustomPriceSelected }"
              data-test="total-vat-included"
            />
            <div
              v-if="priceType === 'chronotruck' && !isCustomPriceSelected"
              @click="isCustomPriceSelected = true; $emit('is-custom-price-selected', true)"
              class="tw-text-blue-500 tw-font-light tw-text-md tw-pl-2 tw-underline tw-cursor-pointer"
              data-test="custom-price-link"
              v-matomo="{
                click: {
                  category: 'Custom offer',
                  action: 'Custom offer link clicked'
                }
              }"
            >
              {{ $t('new-shipment.custom_offer.button') }}
              <ui-material-icon
                v-b-tooltip.hover.html="tooltip"
                name="info"
                class="tw-text-base tw-align-middle tw-ml-1 tw-text-secondary-text"
                @click.native="click"
                data-test="tooltip"
              />
            </div>
            <div
              v-else-if="priceType === 'chronotruck' && isCustomPriceSelected"
              class="tw-flex tw-justify-end tw-items-center tw-ml-1 tw-max-w-48 tw-text-left"
            >
              <ValidationProvider
                ref="${customPriceValueRule-provider}"
                :rules="'required|customPriceValueRule'"
                :name="$t('new-shipment.paragraphs.error.custom_price')"
                data-test="custom-price-input"
              >
                <!-- Documentation about vee-validate flags use in the template https://vee-validate.logaretm.com/v3/guide/state.html#flags -->
                <template v-slot="{ invalid, validated, errors }">
                  <ctk-input-text
                    id="customPrice"
                    name="customPrice"
                    v-model="customPrice"
                    :hint="invalid ? errors[0] : null"
                    :error="invalid && validated"
                    :label="$t('new-shipment.labels.quotation_price.custom_offer')"
                    type="text"
                    suffix="€ HT"
                    class="tw-mb-4 2sm:tw-mb-0 tw-flex-1"
                    required
                  />
                </template>
              </ValidationProvider>
              <ui-ctk-icon
                class="tw-cursor-pointer"
                @click.native="isCustomPriceSelected = false; $emit('is-custom-price-selected', false)"
                name="close"
                data-test="icon"
              />
            </div>
          </div>
        </div>
      </div>
      <new-shipment-quotation-price-lines
        class="tw-px-4 md:tw-px-6 tw-mb-4"
        :open.sync="isOpen"
      >
        <pricing-layout
          class="new-shipment-quotation-price__price-lines__items"
          data-test="price"
        >
          <pricing-lines
            v-for="line in lines"
            :key="line.key"
            :label="line.label"
            data-test="line"
          >
            <span
              v-text="line.value"
              class="tw-text-blue-500 tw-font-bold"
            />
          </pricing-lines>
        </pricing-layout>
        <slot name="price-surge" />
      </new-shipment-quotation-price-lines>
    </div>
  </div>
</template>

<script>
  import { defineComponent, computed } from '@vue/composition-api'

  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  import useStore from '@/composables/useStore'
  import useI18n from '@/composables/useI18n'
  import useTooltipDialog from '@/composables/useTooltipDialog'

  import PricingLayout from '@/components/CtkPricing/_subs/PricingLayout.vue'
  import PricingLines from '@/components/CtkPricing/_subs/PricingLines/index.vue'

  import NewShipmentQuotationPriceLines from './_subs/NewShipmentQuotationPriceLines/index.vue'
  import NewShipmentQuotationPriceHeader from './_subs/NewShipmentQuotationPriceHeader/index.vue'
  import CtkInputText from '@/components/CtkInputs/CtkInputText/index.vue'
  import { extend } from 'vee-validate'
  import { mapActions } from 'vuex'

  /**
   * @module component - NewShipmentQuotationPrice
   * @param {string} title
   * @param {Array<object>} lines
   * @param {String} totalVatIncluded
   * @param {String} totalVatIncluded
   * @param {boolean} [hasCheck=false]
   */
  export default defineComponent({
    name: 'NewShipmentQuotationPrice',
    components: {
      CtkInputText,
      PricingLayout,
      PricingLines,
      NewShipmentQuotationPriceLines,
      NewShipmentQuotationPriceHeader
    },
    props: {
      value: {
        type: Boolean,
        default: null
      },
      priceType: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: true
      },
      totalVatIncluded: {
        type: String,
        required: true
      },
      totalVatExcluded: {
        type: String,
        required: true
      },
      minPrice: {
        type: Number
      },
      hasCheck: {
        type: Boolean,
        default: false
      },
      lines: {
        type: Array,
        required: true
      },
      open: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        customPrice: null
      }
    },
    methods: {
      ...mapActions('shipments/new-shipment', [
        'setCustomPrice'
      ]),
      extendValidationRules () {
        if (this.priceType !== 'chronotruck') {
          return null
        } else {
          extend('customPriceValueRule', value => {
            const floatingPointNumberRegex = new RegExp('\\d+(?:\\.\\d{1,2})?')
            const formatedValue = value.replace(',', '.')
            const currentFloat = formatedValue.match(/(\d+(?:\.\d{1,2})?)/)
            this.customPrice = currentFloat ? currentFloat[0].replace('.', ',') : null

            if ((parseFloat(formatedValue) * 100) < this.minPrice) {
              return this.$t('new-shipment.paragraphs.error.options_price')
            }
            if (this.customPrice) {
              return floatingPointNumberRegex.test(this.customPrice.replace(',', '.')) && parseFloat(this.customPrice.replace(',', '.')) >= 0.01
            }
          })
        }
      }
    },
    watch: {
      value (newValue) {
        if (!newValue) {
          this.isCustomPriceSelected = false
        }
      },
      isCustomPriceSelected (newValue) {
        if (!newValue) {
          this.customPrice = null
        }
      },
      customPrice (newValue) {
        const formattedValue = newValue?.replace(',', '.')

        if (!newValue || ((parseFloat(formattedValue) * 100) < this.minPrice)) {
          this.setCustomPrice(null)
        } else {
          this.setCustomPrice(formattedValue * 100)
        }
      }
    },
    mounted () {
      this.extendValidationRules()
    },
    setup (props) {
      const store = useStore()
      const i18n = useI18n()
      const { state: isOpen } = useModelGetterSetter(props, 'open')
      const { state: quotationPriceType } = useModelGetterSetter(props, 'priceType')
      const { state: isCustomPriceSelected } = false

      const accessibilityIssues = computed(() => store.value.getters['shipments/new-shipment/getAccessibilityIssues'])

      const hasPalletNetworkPriceType = !!quotationPriceType.value && quotationPriceType.value === 'pallet_network'

      const hasAccessibilityIssues = !!accessibilityIssues.value && accessibilityIssues.value.some(issue => [null, undefined, 'lightweight_vehicle'].includes(issue))

      const hasAccessibilityIssuesVL = computed(() => store.value.getters['shipments/new-shipment/hasAccessibilityIssuesVL'])

      const accessibilityWarningMessage = computed(() => {
        const accessibilityIssueType = hasAccessibilityIssuesVL.value ? 'lightweight_vehicle' : 'default'
        const translationKey = `new-shipment.paragraphs.accessibility.warning.${accessibilityIssueType}`
        return i18n.value.t(translationKey)
      })

      const accessibilityWarningIconColor = computed(() => hasAccessibilityIssuesVL.value ? 'red' : 'yellow')

      const content = i18n.value.t('new-shipment.custom_offer.modal.text')
      const { click, haveDialog } = useTooltipDialog(null, content)

      const tooltip = computed(() => ({
        title: content,
        disabled: haveDialog.value
      }))

      return {
        isCustomPriceSelected,
        isOpen,
        accessibilityIssues,
        accessibilityWarningMessage,
        accessibilityWarningIconColor,
        hasAccessibilityIssues,
        hasAccessibilityIssuesVL,
        quotationPriceType,
        hasPalletNetworkPriceType,
        tooltip,
        click,
        haveDialog
      }
    }
  })
</script>

<style lang="scss" scoped>
.new-shipment-quotation-custom-price-price .display-custom-price-button {
  background: $divider;
  color: $info;
}
.new-shipment-quotation-custom-price-price .display-custom-price-button:hover {
  color: $info-darken;
  background: $gray;
}
.new-shipment-quotation-price::v-deep .new-shipment-quotation-price__price-lines__items {
  padding: 0 !important;
}
.new-shipment-quotation-price::v-deep .new-shipment-quotation-price__price-lines__items .pricing-lines {
  padding-top: 0;
  padding-bottom: 0.25rem;
  border-bottom: none;
}
.new-shipment-quotation-price::v-deep .pricing-lines-slot span {
  color: $secondary-text !important;
}
.new-shipment-quotation-price::v-deep .new-shipment-quotation-price--active .pricing-lines-slot span {
  color: $info !important;
}
.new-shipment-quotation-price::v-deep .new-shipment-quotation-price__content .markdown, .new-shipment-quotation-price::v-deep .new-shipment-quotation-price__content .markdown div, .new-shipment-quotation-price::v-deep .new-shipment-quotation-price__content .markdown p {
  display: inline;
}
@media (min-width: 770px) {
  .new-shipment-quotation-price::v-deep .new-shipment-quotation-price__content__inner {
    width: 70%;
  }
  .new-shipment-quotation-price::v-deep .new-shipment-quotation-price__content__price {
    width: 30%;
  }
}
@media (min-width: 1024px) {
  .new-shipment-quotation-price::v-deep .new-shipment-quotation-price .new-shipment-quotation-price-lines {
    margin-top: -20px;
  }
}
.new-shipment-quotation-price::v-deep .new-shipment-quotation-price .new-shipment-quotation-price-lines__header.open {
  border-bottom: 1px solid $divider;
}
.new-shipment-quotation-price::v-deep .new-shipment-quotation-price .new-shipment-quotation-price-lines__header__button {
  color: $secondary-text;
}
.new-shipment-quotation-price::v-deep .new-shipment-quotation-price--active .new-shipment-quotation-price-lines__header.open {
  border-bottom: 1px solid $info;
}
.new-shipment-quotation-price::v-deep .new-shipment-quotation-price--active .new-shipment-quotation-price-lines__header__button {
  color: $info;
}
.new-shipment-quotation-price::v-deep:not(.new-shipment-quotation-price--active) .new-shipment-quotation-price-content__reassurance__content, .new-shipment-quotation-price::v-deep:not(.new-shipment-quotation-price--active) .new-shipment-quotation-price-content__reassurance__content:hover, .new-shipment-quotation-price::v-deep:not(.new-shipment-quotation-price--active) a, .new-shipment-quotation-price::v-deep:not(.new-shipment-quotation-price--active) a:hover {
  color: $secondary-text;
}
.new-shipment-quotation-price::v-deep:not(.new-shipment-quotation-price--active) .new-shipment-quotation-price-content__reassurance__content {
  border-left: 1px solid $divider;
  border-right: 1px solid $divider;
}
.new-shipment-quotation-price {
  border: 1px solid $divider;
}
.new-shipment-quotation-price__header {
  color: $info;
  height: 35px;
  background-color: $divider;
}
.new-shipment-quotation-price__content, .new-shipment-quotation-price__content a, .new-shipment-quotation-price__content a:hover {
  color: $secondary-text;
}
.new-shipment-quotation-price--active {
  border: 1px solid $info;
}
.new-shipment-quotation-price--active .new-shipment-quotation-price__header {
  color: white;
  background-color: $info;
}
.new-shipment-quotation-price--active .new-shipment-quotation-price__content, .new-shipment-quotation-price--active .new-shipment-quotation-price__content a {
  --tw-text-opacity: 1;
  color: rgba(39, 84, 145, var(--tw-text-opacity));
}
.new-shipment-quotation-price--active .new-shipment-quotation-price__content a:hover {
  --tw-text-opacity: 1;
  color: rgba(29, 66, 137, var(--tw-text-opacity));
}
</style>
