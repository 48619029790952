<template>
  <div class="tw-flex new-shipment-goods-types">
    <ui-fat-radio-group
      v-model="selectedType"
      :items="items"
      class="tw-hidden 2sm:tw-flex tw-flex-1"
      data-test="group"
    >
      <template slot-scope="{ item, active, keydown }">
        <new-shipment-goods-types-item
          :icon="item.icon"
          :title="$t(item.title)"
          :subtitle="item.subtitle && $t(item.subtitle)"
          :data-test="item.value"
          :active="active"
          :disabled="$attrs.disabled"
          :class="`tw-w-1/${itemCount}`"
          @keydown.native="keydown"
          @click.native="selectedType = item.value"
        />
      </template>
    </ui-fat-radio-group>

    <ctk-drop-menu
      v-model="selectedType"
      :items="Object.keys(items).map(k => ({
        key: k,
        icon: items[k].icon || null,
        title: `${$t(items[k].title)}${items[k].subtitle ? ` (${$t(items[k].subtitle)})` : ''}`
      }))"
      class="tw-block 2sm:tw-hidden tw-w-full"
    >
      <template #icon>
        <ui-ctk-icon
          v-if="items[selectedType] && items[selectedType].icon"
          :name="items[selectedType].icon"
          class="tw-mr-2"
        />
      </template>
    </ctk-drop-menu>
  </div>
</template>

<script>
  import { computed } from '@vue/composition-api'

  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  import CtkDropMenu from '@/components/CtkDropMenu/index.vue'
  import UiFatRadioGroup from '@/components/UI/FatRadioGroup/index.vue'
  import NewShipmentGoodsTypesItem from './_subs/NewShipmentGoodsTypesItem/index.vue'

  /**
   * @module component - NewShipmentGoodsTypes
   * @param {string} [active=false]
   * @param {string} icon
   * @param {string} title
   */
  export default {
    name: 'NewShipmentGoodsTypes',
    inheritAttrs: false,
    components: {
      CtkDropMenu,
      UiFatRadioGroup,
      NewShipmentGoodsTypesItem
    },
    props: {
      value: {
        type: String,
        default: null
      },
      items: {
        type: Object,
        required: true
      }
    },
    setup (props) {
      const { state: selectedType } = useModelGetterSetter(props, 'value')

      const itemCount = computed(() => {
        return Object.keys(props.items).length
      })

      return {
        selectedType,
        itemCount
      }
    }
  }
</script>

<style lang="scss">

  .new-shipment-goods-types {
    .new-shipment-goods-types-item {
      &:not(:last-child) {
        margin-right: 16px;
      }
    }

    .ctk-drop-menu .ctk-drop-menu__button {
      background-color: $info;

      &:focus {
        @include focusShadow($info);
      }
    }
  }

  @media only screen and (min-width: $breakpoint-tablet) {
    .tw-w-1\/3 {
      width: 33.3333333%;
    }
  }

</style>
