<template>
  <button
    :title="title"
    class="new-shipment-loss-of-entry-dialog-button tw-flex tw-p-0 tw-bg-white tw-w-full"
    type="button"
  >
    <div class="tw-flex tw-items-center">
      <ui-ctk-icon
        :class="[color]"
        :name="icon"
        class="tw-text-5xl"
        data-test="icon"
      />
      <span
        v-text="title"
        data-test="title"
        class="new-shipment-loss-of-entry-dialog-button__title tw-text-sm tw-py-3 tw-pr-3 tw-text-left"
      />
    </div>
  </button>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  /**
   * @module component - NewShipmentLossOfEntryDialogButton
   * @param {string} title
   * @param {string} icon
   * @param {string} color
   */
  export default defineComponent({
    name: 'NewShipmentLossOfEntryDialogButton',
    props: {
      title: {
        type: String,
        required: true
      },
      icon: {
        type: String,
        required: true
      },
      color: {
        type: String,
        required: true
      }
    }
  })
</script>

<style lang="scss" scoped>

  .new-shipment-loss-of-entry-dialog-button {
    min-height: 50px;
    border: 1px solid $gray;

    &:hover {
      background: #F5F5F5;
    }

    &__title {
      color: $secondary-text;
    }
  }

</style>
