<template>
  <ctk-input-select
    v-model="country"
    v-bind="$attrs"
    :items="countries"
    :has-label="false"
    :full-width="false"
    :error="error"
    class="ctk-input-address-countries tw-relative"
    label="Hello"
    @toggle="(v) => $emit('toggle', v)"
  >
    <template
      #currentItem="{ item }"
    >
      <ui-flag
        v-if="item"
        :country="item.value"
        data-test="flag"
      />
    </template>

    <template
      #item="{ item }"
    >
      <div class="tw-flex tw-items-center">
        <ui-flag
          v-if="item"
          :country="item.value"
          data-test="flag"
        />
        <div
          class="tw-truncate"
          v-text="item.text"
        />
      </div>
    </template>
  </ctk-input-select>
</template>

<script>
  // @ts-ignore
  import useCountryLists from '@/composables/useCountryLists'

  import CtkInputSelect from '@/components/CtkInputs/CtkInputSelect/index.vue'
  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  /**
   * @module component - AutocompleteAddressInputCountries
   * @param {Array<string>} [disabledCountries=[]] - A list of ISO-2 country codes to disable
   */
  export default {
    name: 'AutocompleteAddressInputCountries',
    inheritAttrs: false,
    components: {
      CtkInputSelect
    },
    props: {
      value: {
        type: [String],
        default: null
      },
      error: {
        type: Boolean,
        default: null
      },
      disabledCountries: {
        type: Array,
        default: () => []
      }
    },
    setup (props) {
      const { state: country } = useModelGetterSetter(props, 'value')

      return {
        country
      }
    },
    computed: {
      /**
       * @function countries
       * @returns {Array<import('./../../../CtkInputSelect/_subs/CtkInputSelectList/index.vue').ListItem>}
       */
      countries () {
        const { getAvailableCountries } = useCountryLists()
        const availableCountries = getAvailableCountries()

        return availableCountries.map((/** @type {any} **/ v) => ({
          value: v['iso-2'],
          text: this.$t(v['iso-2']),
          disabled: this.disabledCountries.includes(v['iso-2'])
        }))
      }
    }
  }
</script>
