<template>
  <div class="new-shipment-acceptation-dialog-confirmation tw-px-4 md:tw-px-10 tw-pb-4 md:tw-pb-10 tw-pt-8 md:tw-pt-16">
    <div class="new-shipment-acceptation-dialog-confirmation__wrapper tw-flex tw-flex-col tw-mx-auto tw-text-center tw-mb-4">
      <img
        src="@/assets/img/icons/confirmation.svg"
        width="80"
        height="80"
        alt=""
        class="tw-mb-8 tw-mx-auto"
        data-test="icon"
      >

      <h1
        v-text="$t(shipment.price_type === 'pallet_network'
          ? 'new-shipment.titles.quotation.confirmation_pallet_network'
          : 'new-shipment.titles.quotation.confirmation_chronotruck')"
        class="new-shipment-acceptation-dialog-confirmation__title fs-22 tw-font-medium tw-mb-4 md:tw-whitespace-nowrap"
        data-test="title"
      />

      <p
        v-if="!(isSelectedPriceCreditCard && shipment.price_type === 'pallet_network')"
        v-markdown="$t(shipment.price_type === 'pallet_network'
          ? 'new-shipment.paragraphs.quotation.confirmation.assign_right_away'
          : 'new-shipment.paragraphs.quotation.confirmation.best_delay')"
        class="tw-text-secondary-lighten tw-mb-0"
        data-test="best-delay"
      />
      <i18n
        :path="isSelectedPriceCreditCard && shipment.price_type === 'pallet_network'
          ? 'new-shipment.paragraphs.quotation.confirmation.email_payment'
          : 'new-shipment.paragraphs.quotation.confirmation.email'"
        tag="p"
        class="tw-text-secondary-lighten tw-mb-6"
        data-test="email-paragraph"
      >
        <template #email>
          <span
            v-text="getUserInfos.email"
            class="tw-font-bold tw-whitespace-nowrap"
            data-test="email"
          />
        </template>
      </i18n>

      <div class="tw-flex tw-flex-col tw-items-center">
        <template
          v-if="isSelectedPriceCreditCard && shipment.price_type === 'pallet_network'"
        >
          <UiButton
            :to="{
              name: 'Shipment',
              params: {
                uuid: shipment.uuid
              },
              query: {
                'ignore-loss-of-entry': 1
              }
            }"
            class="tw-mx-auto"
            variant="primary"
            v-matomo="{
              click: { category: 'Quotations', action: 'Confirm Shipment Request', name: 'View' }
            }"
            data-test="pay-button"
          >
            {{ $t('app.buttons.track_shipment') }}
          </UiButton>

          <UiLink
            :to="{
              name: 'NewShipment'
            }"
            v-matomo="{
              click: { category: 'Quotations', action: 'Confirm Shipment Request', name: 'New' }
            }"
            class="tw-mt-4 tw-mx-auto"
            replace
            standalone
            data-test="new-shipment"
          >
            {{ $t('app.buttons.new_shipment') }}
          </UiLink>
        </template>
        <template
          v-else
        >
          <UiButton
            v-if="!(isSelectedPriceCreditCard && shipment.price_type === 'pallet_network')"
            :to="{
              name: 'Shipment',
              params: {
                uuid: shipment.uuid
              },
              query: {
                'ignore-loss-of-entry': 1
              }
            }"
            v-matomo="{
              click: { category: 'Quotations', action: 'Confirm Shipment Request', name: 'View' }
            }"
            variant="primary"
            data-test="view-shipment"
          >
            {{ $t(shipment.price_type === 'pallet_network'
              ? 'app.buttons.track_shipment'
              : 'new-shipment.buttons.quotation.confirmation_chronotruck') }}
          </UiButton>
          <UiLink
            :to="{
              name: 'NewShipment'
            }"
            v-matomo="{
              click: { category: 'Quotations', action: 'Confirm Shipment Request', name: 'New' }
            }"
            class="tw-mt-4"
            replace
            standalone
            data-test="new-shipment"
          >
            {{ $t('app.buttons.new_shipment') }}
          </UiLink>
        </template>
      </div>
    </div>

    <img
      src="@/assets/img/illustrations/shipment_confirmation.svg"
      alt=""
      class="new-shipment-acceptation-dialog-confirmation__illustration tw-w-full tw-pointer-events-none tw-select-none tw--mt-8 tw-hidden md:tw-block"
    >
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  /**
   * @module component - NewShipmentAcceptationDialogConfirmation
   * @param {object} shipment - The shipment object
   */
  export default {
    name: 'NewShipmentAcceptationDialogConfirmation',
    props: {
      shipment: {
        type: Object,
        default: null
      }
    },
    computed: {
      ...mapGetters('auth', [
        'getUserInfos'
      ]),
      ...mapGetters('shipments/new-shipment', [
        'isSelectedPriceCreditCard'
      ])
    }
  }
</script>

<style lang="scss" scoped>
.new-shipment-acceptation-dialog-confirmation__wrapper {
  max-width: 480px;
}
.new-shipment-acceptation-dialog-confirmation__illustration {
  -o-object-fit: contain;
  object-fit: contain;
  height: 220px;
}
</style>
