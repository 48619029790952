var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new-shipment-acceptation-dialog-confirmation tw-px-4 md:tw-px-10 tw-pb-4 md:tw-pb-10 tw-pt-8 md:tw-pt-16"},[_c('div',{staticClass:"new-shipment-acceptation-dialog-confirmation__wrapper tw-flex tw-flex-col tw-mx-auto tw-text-center tw-mb-4"},[_c('img',{staticClass:"tw-mb-8 tw-mx-auto",attrs:{"src":require("@/assets/img/icons/confirmation.svg"),"width":"80","height":"80","alt":""}}),_c('h1',{staticClass:"new-shipment-acceptation-dialog-confirmation__title fs-22 tw-font-medium tw-mb-4 md:tw-whitespace-nowrap",domProps:{"textContent":_vm._s(_vm.$t(_vm.shipment.price_type === 'pallet_network'
        ? 'new-shipment.titles.quotation.confirmation_pallet_network'
        : 'new-shipment.titles.quotation.confirmation_chronotruck'))}}),(!(_vm.isSelectedPriceCreditCard && _vm.shipment.price_type === 'pallet_network'))?_c('p',{directives:[{name:"markdown",rawName:"v-markdown",value:(_vm.$t(_vm.shipment.price_type === 'pallet_network'
        ? 'new-shipment.paragraphs.quotation.confirmation.assign_right_away'
        : 'new-shipment.paragraphs.quotation.confirmation.best_delay')),expression:"$t(shipment.price_type === 'pallet_network'\n        ? 'new-shipment.paragraphs.quotation.confirmation.assign_right_away'\n        : 'new-shipment.paragraphs.quotation.confirmation.best_delay')"}],staticClass:"tw-text-secondary-lighten tw-mb-0"}):_vm._e(),_c('i18n',{staticClass:"tw-text-secondary-lighten tw-mb-6",attrs:{"path":_vm.isSelectedPriceCreditCard && _vm.shipment.price_type === 'pallet_network'
        ? 'new-shipment.paragraphs.quotation.confirmation.email_payment'
        : 'new-shipment.paragraphs.quotation.confirmation.email',"tag":"p"},scopedSlots:_vm._u([{key:"email",fn:function(){return [_c('span',{staticClass:"tw-font-bold tw-whitespace-nowrap",domProps:{"textContent":_vm._s(_vm.getUserInfos.email)}})]},proxy:true}])}),_c('div',{staticClass:"tw-flex tw-flex-col tw-items-center"},[(_vm.isSelectedPriceCreditCard && _vm.shipment.price_type === 'pallet_network')?[_c('UiButton',{directives:[{name:"matomo",rawName:"v-matomo",value:({
            click: { category: 'Quotations', action: 'Confirm Shipment Request', name: 'View' }
          }),expression:"{\n            click: { category: 'Quotations', action: 'Confirm Shipment Request', name: 'View' }\n          }"}],staticClass:"tw-mx-auto",attrs:{"to":{
            name: 'Shipment',
            params: {
              uuid: _vm.shipment.uuid
            },
            query: {
              'ignore-loss-of-entry': 1
            }
          },"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('app.buttons.track_shipment'))+" ")]),_c('UiLink',{directives:[{name:"matomo",rawName:"v-matomo",value:({
            click: { category: 'Quotations', action: 'Confirm Shipment Request', name: 'New' }
          }),expression:"{\n            click: { category: 'Quotations', action: 'Confirm Shipment Request', name: 'New' }\n          }"}],staticClass:"tw-mt-4 tw-mx-auto",attrs:{"to":{
            name: 'NewShipment'
          },"replace":"","standalone":""}},[_vm._v(" "+_vm._s(_vm.$t('app.buttons.new_shipment'))+" ")])]:[(!(_vm.isSelectedPriceCreditCard && _vm.shipment.price_type === 'pallet_network'))?_c('UiButton',{directives:[{name:"matomo",rawName:"v-matomo",value:({
            click: { category: 'Quotations', action: 'Confirm Shipment Request', name: 'View' }
          }),expression:"{\n            click: { category: 'Quotations', action: 'Confirm Shipment Request', name: 'View' }\n          }"}],attrs:{"to":{
            name: 'Shipment',
            params: {
              uuid: _vm.shipment.uuid
            },
            query: {
              'ignore-loss-of-entry': 1
            }
          },"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.shipment.price_type === 'pallet_network' ? 'app.buttons.track_shipment' : 'new-shipment.buttons.quotation.confirmation_chronotruck'))+" ")]):_vm._e(),_c('UiLink',{directives:[{name:"matomo",rawName:"v-matomo",value:({
            click: { category: 'Quotations', action: 'Confirm Shipment Request', name: 'New' }
          }),expression:"{\n            click: { category: 'Quotations', action: 'Confirm Shipment Request', name: 'New' }\n          }"}],staticClass:"tw-mt-4",attrs:{"to":{
            name: 'NewShipment'
          },"replace":"","standalone":""}},[_vm._v(" "+_vm._s(_vm.$t('app.buttons.new_shipment'))+" ")])]],2)],1),_c('img',{staticClass:"new-shipment-acceptation-dialog-confirmation__illustration tw-w-full tw-pointer-events-none tw-select-none tw--mt-8 tw-hidden md:tw-block",attrs:{"src":require("@/assets/img/illustrations/shipment_confirmation.svg"),"alt":""}})])}
var staticRenderFns = []

export { render, staticRenderFns }