<template>
  <new-shipment-acceptation-dialog
    v-model="dialogValue"
    v-on="$listeners"
    :confirmation="confirmation"
    :error="error"
    :shipment="shipment"
    :title="$t('new-shipment.titles.quotation_acceptation')"
    :has-price="true"
  >
    <ValidationObserver
      ref="observer"
      slim
    >
      <form
        class="tw-w-full tw-p-4 md:tw-p-8 md:tw-pt-24"
        data-test="form"
        @submit.prevent="submitted"
      >
        <div
          data-test="reassurances"
        >
          <new-shipment-acceptation-dialog-reassurance
            v-for="(reassurance, k) in reassurances"
            :key="k"
            :icon="reassurance.icon"
            :title="$t(reassurance.title)"
            :content="$t(reassurance.content)"
            :highlight="reassurance.highlight"
            :data-test="`reassurance-${k}`"
            class="tw-mb-10"
          />
        </div>

        <b-form-checkbox
          v-model="formData.tos"
          name="terms-of-service"
          class="new-shipment-acceptation-dialog__checkbox new-shipment-acceptation-dialog__tos tw-mb-10"
          required
          data-test="tos"
        >
          {{ $t('app.paragraphs.tos') }}
        </b-form-checkbox>

        <new-shipment-from-external-request-info class="tw-mb-3" />

        <div class="tw-flex tw-justify-center tw-pb-4 md:tw-pb-0">
          <ui-button
            :loading="$wait.is('accepting quotation')"
            :disabled="$wait.is('accepting quotation') || !formData.tos"
            data-test="submit"
            class="tw-w-full"
            variant="primary"

            type="submit"
          >
            {{ $t('validate') | capitalize }}
          </ui-button>
        </div>
      </form>
    </ValidationObserver>
  </new-shipment-acceptation-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'

  import { offers } from '@/../config/chronoinfos'
  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  import NewShipmentFromExternalRequestInfo
    from '@/views/Shippers/NewShipment/components/NewShipmentFromExternalRequestInfo/index.vue'
  import NewShipmentAcceptationDialog from '@/views/Shippers/NewShipment/_subs/NewShipmentInformations/components/NewShipmentAcceptationDialog/index.vue'
  import NewShipmentAcceptationDialogReassurance from '@/views/Shippers/NewShipment/_subs/NewShipmentInformations/components/NewShipmentAcceptationDialog/_subs/NewShipmentAcceptationDialogReassurance/index.vue'

  /**
   * Acceptation dialog when the user goes to the chronotruck offers
   * without payment.
   * @module component - NewShipmentAcceptationBookingDialog
   * @param {boolean} [value=false]
   * @param {boolean} [confirmation=false]
   * @param {boolean} [error=false]
   * @param {object} [shipment=null]
   */
  export default {
    name: 'NewShipmentAcceptationBookingDialog',
    components: {
      NewShipmentFromExternalRequestInfo,
      NewShipmentAcceptationDialog,
      NewShipmentAcceptationDialogReassurance
    },
    props: {
      value: {
        type: Boolean,
        default: false
      },
      confirmation: {
        type: Boolean,
        default: false
      },
      error: {
        type: Boolean,
        default: false
      },
      shipment: {
        type: Object,
        default: null
      }
    },
    setup (props) {
      const { state: dialogValue } = useModelGetterSetter(props, 'value')

      return {
        dialogValue
      }
    },
    data () {
      return {
        offers,
        formData: {
          tos: false
        }
      }
    },
    computed: {
      ...mapGetters('auth', [
        'isCompanyPayingCC'
      ]),
      ...mapGetters('shipments/new-shipment', [
        'getQuotationSelectedPrice',
        'isSelectedPriceThirtyDaysInvoice',
        'isSelectedPriceNetwork'
      ]),
      /**
       * @function reassurances
       * @returns {object}
       */
      reassurances () {
        const payment = {
          icon: require('@/assets/img/icons/quotation/payment.svg'),
          title: 'new-shipment.titles.quotation.payment',
          content: 'new-shipment.paragraphs.quotation.payment.credit_card',
          highlight: true
        }

        if (!this.isCompanyPayingCC) {
          payment.content = 'new-shipment.paragraphs.quotation.payment'
          payment.highlight = false
        }

        return {
          ...(this.isSelectedPriceNetwork
            ? {
              engagment: {
                icon: require('@/assets/img/icons/quotation/contract.svg'),
                title: 'app.labels.engagement',
                content: 'new-shipment.paragraphs.quotation.engagment_pallet_network'
              },
              'cancel-without-fee': {
                icon: require('@/assets/img/icons/quotation/cancel.svg'),
                title: 'app.titles.cancel-without-fee',
                content: 'app.paragraphs.cancel-without-fee'
              }
            }
            : {
              engagment: {
                icon: require('@/assets/img/icons/quotation/contract.svg'),
                title: 'app.labels.engagement',
                content: 'new-shipment.paragraphs.quotation.engagment'
              },
              'cancel-without-fee': {
                icon: require('@/assets/img/icons/quotation/cancel.svg'),
                title: 'app.titles.cancel-without-fee',
                content: 'new-shipment.paragraphs.quotation.cancel-without-fee'
              }
            }
          ),
          payment
        }
      }
    },
    methods: {
      /**
       * Called whenever the form is submitted
       * @function submitted
       */
      submitted () {
        if (this.$matomo) {
          const { type, payment_plans: paymentPlans } = this.getQuotationSelectedPrice
          this.$matomo.trackEvent('Quotations', 'Validated Submission', `${type}|${paymentPlans[0]}`)
        }

        this.$refs.observer.validate()
          .then(async valid => {
            if (!valid || this.$wait.is('accepting quotation')) return false

            if (!this.formData.tos) return false

            this.$parent.acceptationSubmitted()
          })
      }
    }
  }
</script>
