<template>
  <ValidationProvider
    :name="$t(`new-shipment.fields.${direction}.date`)"
    rules="required"
    tag="div"
    class="new-shipment-dates-calendar"
  >
    <template slot-scope="{ errors, invalid, validated }">
      <ctk-date-time-picker
        :ref="`${direction}-date`"
        :id="`${direction}-date`"
        v-model="date"
        :name="`${direction}-date`"
        :class="{
          'has-error': invalid && validated
        }"
        :min-date="minDate"
        :max-date="maxDate"
        :locale="$i18n.locale"
        color="#021D49"
        button-color="#021D49"
        format="YYYY-MM-DD"
        no-header
        only-date
        no-weekends-days
        no-shortcuts
        inline
        no-button-now
      />

      <span
        v-if="invalid && validated"
        class="tw-text-red-500 fs-12"
        data-test="error"
      >
        {{ errors[0] }}
      </span>
    </template>
  </ValidationProvider>
</template>

<script>
  import CtkDateTimePicker from 'vue-ctk-date-time-picker'
  import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'

  /**
   * @module component - NewShipmentDatesCalendar
   * @param {string} direction
   * @param {string} maxDate
   * @param {string} minDate
   * @param {boolean} [disabled=false]
   */
  export default {
    name: 'NewShipmentDatesCalendar',
    components: {
      CtkDateTimePicker
    },
    props: {
      value: {
        type: String,
        default: null
      },
      maxDate: {
        type: String,
        default: null
      },
      minDate: {
        type: String,
        default: null
      },
      direction: {
        type: String,
        required: true
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      date: {
        get () {
          return this.value
        },
        set (v) {
          if (this.disabled) return false

          this.$emit('input', v)
        }
      }
    }
  }
</script>

<style lang="scss">

  .new-shipment-dates-calendar {
    .date-time-picker {
      border: 1px solid $light-gray;

      &.has-error {
        border: 1px solid $danger;
      }
    }

    .date-time-picker .datepicker-buttons-container {
      display: none !important;
    }
  }

</style>
