<template>
  <div class="new-shipment-dates-optimal tw-flex tw-flex-col md:tw-flex-row tw-p-5 tw-relative">
    <new-shipment-dates-optimal-direction
      :date="pickup"
      :editable="true"
      :start-time="timeSlot.startTime.format('LT')"
      :end-time="timeSlot.endTime.format('LT')"
      id="pickup-optimal"
      direction="pickup"
      data-test="pickup"
      class="tw-relative md:tw-w-1/2 tw-mb-4 md:tw-mb-0 md:tw-mr-8"
      @edit="edit"
    />
    <new-shipment-dates-optimal-direction
      :date="delivery"
      :start-time="timeSlot.startTime.format('LT')"
      :end-time="timeSlot.endTime.format('LT')"
      direction="delivery"
      data-test="delivery"
      class="md:tw-w-1/2"
    />

    <transition
      name="fade"
    >
      <new-shipment-dates-optimal-datepicker
        v-show="editing"
        ref="datepicker"
        :pickup.sync="pickupValue"
        :delivery.sync="deliveryValue"
        :target="'#pickup-optimal'"
        :distance="distance"
        class="tw-absolute"
        @update:pickup="$emit('update')"
        @close="closeDatepicker"
      />
    </transition>
  </div>
</template>

<script>
  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  import NewShipmentDatesOptimalDatepicker from './_subs/NewShipmentDatesOptimalDatepicker/index.vue'
  import NewShipmentDatesOptimalDirection from './_subs/NewShipmentDatesOptimalDirection/index.vue'

  /**
   * @module component - NewShipmentDatesOptimal
   * @param {string|object} pickup
   * @param {string|object} delivery
   * @param {number} [distance=null]
   * @emits update
   */
  export default {
    name: 'NewShipmentDatesOptimal',
    props: {
      pickup: {
        type: [String, Object],
        required: true
      },
      delivery: {
        type: [String, Object],
        required: true
      },
      distance: {
        type: Number,
        default: null
      }
    },
    data () {
      return {
        editing: false,
        closable: false,
        timeSlot: {
          startTime: this.$moment().startOf('day').add(540, 'minutes'),
          endTime: this.$moment().startOf('day').add(1020, 'minutes')
        }
      }
    },
    setup (props) {
      const { state: pickupValue } = useModelGetterSetter(props, 'pickup')
      const { state: deliveryValue } = useModelGetterSetter(props, 'delivery')

      return {
        pickupValue,
        deliveryValue
      }
    },
    methods: {
      /**
       * @function edit
       */
      async edit () {
        this.editing = true
        this.$refs.datepicker.popperInstance.update()

        /**
         * Disallow the user to close the datepicker for 100ms
         * to make sure the transition animation is shown.
         * Issue caused by the click outside of the datepicker component.
         */
        this.closable = false
        setTimeout(() => {
          this.closable = true
        }, 100)
      },
      /**
       * @function closeDatepicker
       */
      closeDatepicker () {
        if (this.closable) {
          this.editing = false
        }
      }
    },
    components: {
      NewShipmentDatesOptimalDatepicker,
      NewShipmentDatesOptimalDirection
    }
  }
</script>

<style lang="scss" scoped>

  .new-shipment-dates-optimal {
    background-color: #F9F9F9;
    border: 1px solid #F2F2F2;

    .new-shipment-dates-optimal-direction:first-child::after {
      content: '';
      position: absolute;
      right: -1rem;
      height: 100%;
      width: 1px;
      background-color: $gray;
    }

    .new-shipment-dates-optimal-datepicker {
      z-index: 10;
    }
  }

</style>
