<template>
  <div class="new-shipment-dates-timeslot tw-relative">
    <div
      class="tw-text-center mb-3"
      data-test="label"
    >
      {{ $t('app.labels.timeslot') | capitalize }}
    </div>
    <UiBadge
      v-if="hasPrice"
      color="orange"
      size="sm"
      class="tw-absolute tw-right-0 tw-top-0"
      data-test="price"
    >
      +{{ additionalFee | currency('EUR', $i18n.locale, true) }}
    </UiBadge>
    <vue-slider
      ref="slider"
      v-model="timeslot"
      v-bind="options"
      :min="360"
      :max="1200"
      :min-range="4"
      :interval="15"
      :height="4"
      :dot-size="12"
      :disabled="disabled"
      :dot-options="dotOptions"
      class="slider"
      data-test="slider"
    />
    <div
      class="new-shipment-dates-timeslot__values tw-text-center tw-font-medium"
      data-test="values"
    >
      <span data-test="start-time">{{ startTime | moment('LT') }}</span> - <span data-test="end-time">{{ endTime | moment('LT') }}</span>
    </div>
  </div>
</template>

<script>
  import VueSlider from 'vue-slider-component'
  import 'vue-slider-component/theme/default.css'

  function getOptions () {
    return {
      railStyle: { backgroundColor: '#E9E9EA' },
      piecewiseStyle: { boxShadow: 'none', width: '40px', height: '20px' },
      dotStyle: { backgroundColor: '#277696', boxShadow: 'none' },
      processStyle: { backgroundColor: '#277696' },
      tooltip: 'none'
    }
  }

  /**
   * @module component - NewShipmentDatesTimeslot
   * @param {boolean} [disabled=false]
   */
  export default {
    name: 'NewShipmentDatesTimeslot',
    components: {
      VueSlider
    },
    props: {
      value: {
        type: Array,
        default: null
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        options: getOptions()
      }
    },
    computed: {
      timeslot: {
        get () {
          return [Math.max(360, this.value[0]), Math.max(420, Math.min(1200, this.value[1]))]
        },
        set (v) {
          this.$emit('input', v)
        }
      },
      dotOptions () {
        return [{
          max: this.timeslot[1] - 60
        }, {
          min: this.timeslot[0] + 60
        }]
      },
      startTime () {
        return this.$moment().startOf('day').minutes(this.timeslot[0])
      },
      endTime () {
        return this.$moment().startOf('day').minutes(this.timeslot[1])
      },
      /**
       * Returns true if there are additional fees
       * @function hasPrice
       * @returns {boolean}
       */
      hasPrice () {
        const diff = this.timeslot[1] - this.timeslot[0]
        return diff <= 120
      },
      /**
       * Returns the additional fee due to timeslot
       * @function additionalFee
       * @returns {number} feeAmount
       */
      additionalFee () {
        const diff = this.timeslot[1] - this.timeslot[0]
        if (diff <= 75) {
          return 1400
        }

        return 800
      }
    }
  }
</script>
