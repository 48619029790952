<template>
  <ctk-dialog
    :value="value"
    modal-class="new-shipment-loss-of-entry-dialog"
    max-width="440px"
    persistent
    hide-header
    hide-footer
  >
    <div class="new-shipment-loss-of-entry-dialog__wrapper tw-relative">
      <div class="new-shipment-loss-of-entry-dialog__content tw-p-4 2sm:tw-p-8">
        <h1
          data-test="title"
          class="new-shipment-loss-of-entry-dialog__content__title tw-relative tw-mb-0 tw-font-normal tw-text-secondary tw-w-full"
          v-text="$t('new-shipment.titles.leave-form')"
        />
        <div class="tw-flex tw-flex-col tw-mt-10">
          <new-shipment-loss-of-entry-dialog-button
            :title="$t('new-shipment.buttons.leave-form.continue')"
            icon="right"
            color="tw-text-blue-500"
            class="tw-mb-3"
            data-test="continue"
            @click.native="confirm(true)"
          />
          <new-shipment-loss-of-entry-dialog-button
            :title="$t('new-shipment.buttons.leave-form.leave')"
            icon="close-rounded"
            color="tw-text-red-500"
            data-test="leave"
            @click.native="confirm(false)"
          />
        </div>
      </div>
    </div>
  </ctk-dialog>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import CtkDialog from '@/components/CtkDialog/index.vue'
  import NewShipmentLossOfEntryDialogButton from './_subs/NewShipmentLossOfEntryDialogButton/index.vue'

  /**
   * @module component - NewShipmentLossOfEntryDialog
   */
  export default defineComponent({
    name: 'NewShipmentLossOfEntryDialog',
    components: {
      CtkDialog,
      NewShipmentLossOfEntryDialogButton
    },
    props: {
      value: {
        type: Boolean,
        required: true
      }
    },
    methods: {
      confirm (v) {
        this.$emit('confirm', v)
      }
    }
  })
</script>

<style lang="scss" scoped>

  .new-shipment-loss-of-entry-dialog {
    &__content {
      &__title {
        font-size: 21px;
        line-height: 21px;

        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -10px;
          width: 190px;
          height: 1px;
          background-color: $light-gray;
        }
      }
    }

    &__wrapper {
      padding-bottom: 170px;

      &::after {
        position: absolute;
        bottom: 16px;
        width: 100%;
        min-height: 170px;
        content: '';
        background-size: cover;
        background-image: url('~@/assets/img/illustrations/welcome_reversed.svg');
        background-position: center bottom;
        opacity: 0.8;
      }
    }
  }

</style>
