<template>
  <div class="new-shipment-address-available-countries tw-relative tw-flex tw-flex-1">
    <new-shipment-address-available-countries-list
      :title="$t('new-shipment.titles.add_address.countries_available')"
      :disabled-countries="disabledCountries"
      @select="(country) => $emit('select-country', country)"
    />
  </div>
</template>

<script>
  import NewShipmentAddressAvailableCountriesList from './_subs/NewShipmentAddressAvailableCountriesList'

  /**
   * @module component - NewShipmentAddressAvailableCountries
   * @param {Array} [disabledCountries=[]] - A list of ISO-2 country codes to disable
   */
  export default {
    name: 'NewShipmentAddressAvailableCountries',
    props: {
      disabledCountries: {
        type: Array,
        default: () => []
      }
    },
    components: {
      NewShipmentAddressAvailableCountriesList
    }
  }
</script>

<style lang="scss" scoped>

  .new-shipment-address-available-countries {
    transition: opacity 200ms;

    .new-shipment-address-available-countries-list {
      position: absolute;
      min-width: 550px;
      max-width: 750px;
      left: 40px;
      top: 60px;
    }

    &__map {
      min-height: 100%;
      max-height: 600px;
      margin-left: auto;
      user-select: none;
    }

    &.faded {
      opacity: 0.4;
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      .new-shipment-address-available-countries-list {
        position: relative;
        left: 0;
        top: 0;
        min-width: auto;
        max-width: 100%;
        margin-bottom: 16px;
      }
    }
  }

</style>
