
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  functional: true,
  render (h, { slots, data }) {
    return h('div', {
      class: [
        'new-shipment-alert tw-px-4 tw-text-sm tw-py-3 tw-mb-2 tw-leading-snug tw-bg-gray-300 tw-text-gray-700',
        data.staticClass
      ],
      attrs: data.attrs
    }, [
      slots().default
    ])
  }
})
