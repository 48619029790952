<template>
  <div class="tw-mb-4 tw-pt-4 new-shipment-goods-pallet-total">
    <div class="tw-flex tw-flex-col 2sm:tw-flex-row md:tw-w-3/4">
      <ValidationProvider
        ref="height-provider"
        :name="$t('new-shipment.fields.goods.height')"
        :rules="'required|min_value:1|max_value:270|numeric'"
        slim
      >
        <template slot-scope="{ invalid, validated, errors }">
          <ctk-input-text
            id="height"
            v-model="heightComputed"
            :error="invalid && validated || errors && errors.length > 0"
            :label="$t('new-shipment.labels.goods.height_total')"
            :disabled="$wait.is('requesting quotation')"
            name="height"
            type="tel"
            suffix="cm"
            class="tw-mb-4 2sm:tw-mr-4 2sm:tw-mb-0 tw-flex-1"
            data-test="height"
            required
            @input="updateGuard"
          />
        </template>
      </ValidationProvider>
      <ValidationProvider
        ref="weight-provider"
        :name="$t('app.labels.load.weight')"
        :rules="'required|min_value:50|max_value:28000|numeric'"
        slim
      >
        <template slot-scope="{ invalid, validated, errors }">
          <ctk-input-text
            id="weight"
            v-model="weightComputed"
            :error="invalid && validated || errors && errors.length > 0"
            :label="$t('new-shipment.labels.goods.weight_total')"
            :disabled="$wait.is('requesting quotation')"
            name="weight"
            type="tel"
            class="tw-mb-4 2sm:tw-mr-4 2sm:tw-mb-0 tw-flex-1"
            suffix="kg"
            data-test="weight"
            required
            @input="updateGuard"
          />
        </template>
      </ValidationProvider>
      <div
        v-text="$t('lm', {
          lm: getPalletsMpl || '-'
        })"
        class="new-shipment-goods-pallet-total__lm tw-ml-auto 2sm:tw-ml-0 tw-px-4 tw-text-blue-500 tw-text-center"
        data-test="mpl"
      />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import useModelGetterSetter from '@/composables/useModelGetterSetter'
  import CtkInputText from '@/components/CtkInputs/CtkInputText'

  /**
   * @module component - NewShipmentGoodsPalletTotal
   */
  export default {
    name: 'NewShipmentGoodsPalletTotal',
    components: {
      CtkInputText
    },
    props: {
      height: {
        type: [Number, String],
        default: null
      },
      weight: {
        type: [Number, String],
        default: null
      }
    },
    setup (props) {
      const { state: heightComputed } = useModelGetterSetter(props, 'height')
      const { state: weightComputed } = useModelGetterSetter(props, 'weight')

      return {
        heightComputed,
        weightComputed
      }
    },
    computed: {
      ...mapGetters('shipments/new-shipment', [
        'getPalletsMpl'
      ])
    },
    methods: {
      updateGuard () {
        this.$emit('update-guards')
      }
    }
  }
</script>

<style lang="scss" scoped>

  .new-shipment-goods-pallet-total {
    border-top: 1px solid $divider;

    &__lm {
      line-height: 42px;
      min-width: 90px;
      background-color: $light-gray;

      @media only screen and (max-width: $breakpoint-mobile-l) {
        min-height: 42px;
      }
    }
  }

</style>
