<template>
  <div
    v-click-outside="close"
    class="new-shipment-dates-optimal-datepicker tw-bg-white tw-px-3 tw-py-5 tw-shadow"
  >
    <div>
      <label
        class="tw-font-bold tw-text-sm tw-mb-2"
        data-test="title"
        v-text="$t('new-shipment.labels.dates.select.pickup')"
      />
      <table
        class="tw-w-full"
        role="grid"
      >
        <thead>
          <tr
            data-test="weekdays"
          >
            <td
              v-for="(week, k) in weekDays"
              :key="k"
              :data-test="`${k}-weekday`"
              v-text="week"
              class="new-shipment-dates-optimal-datepicker__weeks__item tw-flex-1 tw-text-center tw-text-xs tw-capitalize"
            />
          </tr>
        </thead>
        <tbody
          data-test="weeks"
        >
          <tr
            v-for="(week, k) in weeks"
            :key="k"
            :data-test="`week-${k}`"
            class="tw-w-full"
          >
            <td
              v-for="(day, z) in week.days"
              :data-test="`day-${k}-${z}`"
              :key="z"
              class="new-shipment-dates-optimal-datepicker__days__cell"
            >
              <button
                :disabled="day.isDisabled"
                :data-test="`day-${k}-${z}-button`"
                :data-day-week="day.moment.weekday()"
                :data-day-month="day.moment.date()"
                :title="day.moment.format('LL')"
                :class="{
                  'new-shipment-dates-optimal-datepicker__days__item--selected-pickup': selectedPickup && selectedPickup.startOf('day').isSame(day.moment, 'day'),
                  'new-shipment-dates-optimal-datepicker__days__item--selected-delivery': selectedDelivery && selectedDelivery.startOf('day').isSame(day.moment, 'day'),
                  'new-shipment-dates-optimal-datepicker__days__item--between': selectedPickup && selectedDelivery && day.moment.isBetween(selectedPickup, selectedDelivery),
                  'new-shipment-dates-optimal-datepicker__days__item--current-day': day.moment.isSame($moment(), 'date')
                }"
                type="button"
                class="new-shipment-dates-optimal-datepicker__days__item tw-flex tw-h-12 tw-border-none tw-text-sm tw-w-full"
                @click="select(day.moment)"
              >
                <div
                  class="tw-flex tw-m-auto tw-capitalize"
                  v-text="$moment(day.moment).format(day.isFirstLastDay ? 'll' : 'DD')"
                />
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        data-test="buttons"
        class="new-shipment-dates-optimal-datepicker__buttons tw-flex tw-justify-end tw-pt-3 tw-mt-5"
      >
        <ui-button
          :title="$t('cancel') | capitalize"
          type="button"
          class="tw-mr-4"
          variant="link"
          data-test="cancel"
          @click="close"
        >
          {{ $t('cancel') | capitalize }}
        </ui-button>
        <ui-button
          :disabled="!(selectedPickup && selectedDelivery)"
          :title="$t('validate') | capitalize"
          type="button"
          variant="primary"
          data-test="validate"
          @click="validate"
        >
          <template #left-icon>
            <ui-material-icon
              name="check"
              data-test="icon"
            />
          </template>
        </ui-button>
      </div>
    </div>
  </div>
</template>

<script>
  import { directive } from 'v-click-outside'
  import { createPopper } from '@popperjs/core'

  import { computeDates } from '@/views/Shippers/NewShipment/_subs/NewShipmentDates/index.vue'

  /**
   * @module component - NewShipmentDatesOptimalDatepicker
   * @param {string} [pickup=null]
   * @param {string} [delivery=null]
   * @param {string} [target=null] - CSS selector for the target element
   * @param {number} [distance=null] - Distance in km
   * @emits close
   * @emits update:pickup
   * @emits update:delivery
   */
  export default {
    name: 'NewShipmentDatesOptimalDatepicker',
    props: {
      pickup: {
        type: [String, Object],
        default: null
      },
      delivery: {
        type: [String, Object],
        default: null
      },
      target: {
        type: String,
        default: null
      },
      distance: {
        type: Number,
        default: null
      }
    },
    directives: {
      clickOutside: directive
    },
    data () {
      return {
        /** @type {any} */
        selectedPickup: null,
        /** @type {any} */
        selectedDelivery: null,
        /** @type {any} */
        popperInstance: null
      }
    },
    watch: {
      pickup (v) {
        this.select(v)
      }
    },
    mounted () {
      if (this.target) {
        /** @type {any} */
        const button = document.querySelector(this.target)
        /** @type {any} */
        const tooltip = this.$el

        this.popperInstance = createPopper(button, tooltip, {
          placement: 'bottom-start',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 16]
              }
            }
          ]
        })
      }

      this.select(this.pickup)
    },
    beforeDestroy () {
      if (this.popperInstance) {
        this.popperInstance.destroy()
        this.popperInstance = null
      }
    },
    computed: {
      /**
       * @function weekDays
       * @returns {Array<string>}
       */
      weekDays () {
        return this.$moment.weekdaysShort(true)
      },
      /**
       * @function weeks
       * @returns {Array<any>}
       */
      weeks () {
        const currentDate = this.$moment()
        const startWeek = currentDate.clone().startOf('week')

        /**
         * The number of weeks to be displayed depends if there is a delivery date in the 5th line.
         * If not, fallback to 4 weeks.
         */
        let NUMBER_OF_WEEKS = 4
        if (this.selectedDelivery) {
          const deliveryDate = this.selectedDelivery.clone()
          if (deliveryDate.diff(startWeek, 'days') >= 7 * 4) {
            NUMBER_OF_WEEKS = 5
          }
        }

        const weeks = []
        let dayIndex = 0
        for (let i = 1; i < NUMBER_OF_WEEKS + 1; i++) {
          const days = []

          for (let z = 0; z <= 6; z++) {
            days.push(this.$moment(startWeek).add(dayIndex, 'days'))
            dayIndex += 1
          }

          weeks.push({
            days: days.map(day => ({
              moment: day,
              isDisabled: day < currentDate || day.clone().day() === 0 || day.clone().day() === 6 || i === 5,
              isFirstLastDay: day.clone().endOf('month').isSame(day, 'date') ||
                day.clone().startOf('month').isSame(day, 'date') ||
                day.clone().isSame(startWeek.clone(), 'date')
            }))
          })
        }

        return weeks
      }
    },
    methods: {
      /**
       * @function close
       */
      close () {
        this.$emit('close')
      },
      /**
       * @function select
       * @param {object} day
       */
      select (day) {
        const { pickup, delivery } = computeDates(day, this.distance)

        this.selectedPickup = this.$moment(pickup)
        this.selectedDelivery = this.$moment(delivery)
      },
      /**
       * @function validate
       */
      validate () {
        this.$emit('update:pickup', this.selectedPickup)
        this.$emit('update:delivery', this.selectedDelivery)
        this.close()
      }
    }
  }
</script>

<style lang="scss" scoped>

  .new-shipment-dates-optimal-datepicker {
    &__weeks__item {
      color: #A4A4A4;
    }

    &__days {
      &__cell {
        width: 14.285%;
      }

      &__item {
        background-color: #F0F0F0;
        min-height: 54px;
        min-width: 54px;

        &:disabled,
        &--current-day {
          background-color: #FAFAFA;
        }

        &:disabled {
          cursor: not-allowed;
        }

        &--current-day {
          border: 1px solid #F2F2F2;
        }

        &:hover:not(:disabled),
        &--selected-pickup {
          background-color: $info;
          color: white;
        }

        &--selected-pickup,
        &--selected-pickup:hover {
          border-radius: 0.25rem 0 0 0.25rem;
        }

        &--selected-delivery,
        &--selected-delivery:disabled {
          background-color: $primary;
          color: white;
          border-radius: 0 0.25rem 0.25rem 0;
        }

        &--between {
          background-color: #C2D4DA;
          border-radius: 0;
        }

        &--between:disabled {
          background-color: #CADBE2;
        }
      }
    }

    &__buttons {
      border-top: 1px solid $divider;
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      position: fixed !important;
      top: 0 !important;
      left: 0 !important;
      width: 100vw;
      height: 100vh;
      transform: translate(0) !important;
      z-index: 999 !important;

      &__days__item {
        min-width: 14.285%;
      }
    }
  }

</style>
