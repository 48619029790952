var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"new-shipment-quotation-actions tw-flex tw-flex-col md:tw-flex-row md:tw-items-center md:tw-justify-between tw-list-none tw-m-0 tw-p-0 tw-py-3"},[(_vm.getQuotation)?_c('li',{staticClass:"new-shipment-quotation-actions__carbon-footprint tw-pb-2 md:tw-pb-0 tw-flex tw-items-center tw-text-sm"},[_c('img',{staticClass:"tw-mr-2",attrs:{"src":require("@/assets/img/icons/co2.svg"),"alt":"","width":"20","height":"20"}}),_c('div',{staticClass:"tw-flex tw-flex-col md:tw-flex-row md:tw-items-center"},[_c('div',{staticClass:"tw-mr-1 tw-text-secondary-text",domProps:{"textContent":_vm._s(_vm.$t('new-shipment.labels.carbon_footprint'))}}),_c('div',{staticClass:"tw-flex tw-items-center"},[_c('div',{staticClass:"tw-font-medium tw-text-secondary-lighten",domProps:{"textContent":_vm._s(_vm.$t('shipment.values.co2', {
            weight: _vm.co2
          }))}}),(_vm.tooltip)?_c('ui-material-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.html",value:(_vm.tooltip),expression:"tooltip",modifiers:{"hover":true,"html":true}}],staticClass:"tw-text-base tw-align-middle tw-ml-1 tw-text-secondary-text",attrs:{"name":"info"},nativeOn:{"click":function($event){return _vm.click.apply(null, arguments)}}}):_vm._e()],1)])]):_vm._e(),(_vm.getQuotation)?_c('li',{staticClass:"new-shipment-quotation-actions__item tw-self-end md:tw-self-center tw-pt-2 md:tw-pt-0"},[_c('inline-file-download',{attrs:{"url":_vm.fileUrl},on:{"file":_vm.fileGenerated},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var loading = ref.loading;
          var download = ref.download;
          var error = ref.error;
return [_c('ui-button',{directives:[{name:"matomo",rawName:"v-matomo",value:({
            click: {
              category: 'Quotations',
              action: 'Download Quote'
            }
          }),expression:"{\n            click: {\n              category: 'Quotations',\n              action: 'Download Quote'\n            }\n          }"}],staticClass:"tw-relative tw-flex tw-items-center",class:{
            'tw-cursor-wait': loading
          },attrs:{"disabled":loading,"loading":loading,"title":_vm.$t('new-shipment.buttons.quotation.download'),"id":"download-quotation","href":"#","type":"button","variant":"link"},on:{"click":function($event){$event.preventDefault();return download.apply(null, arguments)}}},[_c('transition',{attrs:{"name":"fade"}},[(error)?_c('div',{staticClass:"tw-absolute tw-flex tw-items-center tw-left-0"},[_c('i',{staticClass:"tw-text-red-500 tw-text-3xl icon-ctk-warning",attrs:{"id":"download-file-warning","aria-hidden":"true"}}),_c('b-tooltip',{attrs:{"target":"download-file-warning"}},[_vm._v(" "+_vm._s(_vm.$t('app.paragraphs.error_while_downloading_file'))+" ")])],1):_vm._e()]),_c('span',{domProps:{"textContent":_vm._s(_vm.$t('new-shipment.buttons.quotation.download'))}})],1),(_vm.popoverVisible)?_c('b-popover',{ref:"popover",attrs:{"target":'download-quotation',"show":_vm.popoverVisible,"disabled":true,"placement":"bottom"},on:{"update:show":function($event){_vm.popoverVisible=$event}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"ctk-btn-file tw-flex tw-items-center"},[_c('span',{staticClass:"tw-font-normal tw-mr-2",domProps:{"textContent":_vm._s(_vm.$t('new-shipment.titles.quotation.quotation_pdf'))}}),_c('ui-button',{staticClass:"close-popover",attrs:{"title":_vm._f("capitalize")(_vm.$t('close')),"variant":"link","size":"sm"},on:{"click":function($event){_vm.popoverVisible = false}},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('ui-ctk-icon',{attrs:{"name":"close"}})]},proxy:true}],null,true)})],1)]},proxy:true}],null,true)},[_c('div',{staticClass:"tw-flex"},[(_vm.filePath)?_c('ui-button',{staticClass:"tw-w-full",attrs:{"id":"download-button","href":_vm.filePath,"download":"","variant":"info","size":"sm","target":"_blank"}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t('app.buttons.download')))+" ")]):_vm._e()],1)]):_vm._e()]}}],null,false,3729003061)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }